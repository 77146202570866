import { createHourByDateApi, getCustomerApi } from "../../services/hours";
import { types as hourListTypes } from "../../reducers/hours/hourList";
import { types as hourListOldTypes } from "../../reducers/hours/hourListOld";
import { createTaskByProject } from "../../reducers/hours/tasksByProject";
import { createProject } from "../../reducers/hours/projects";
import { getErrorCode, logout } from "../../Utilities/api.config";
import moment from "moment";
import {
  getWeekday,
  getStartAndEndTimeFromAmount,
  getAmountFromStartAndEndTime,
} from "../../Utilities/utils";
import i18next from "i18next";
const initialState = {
  showDialog: false,
  isLoading: false,
  weekDay: null,
  error: null,
  formState: {},
};
const types = {
  TOGGLE_DIALOG: "createHour/TOGGLE_DIALOG",
  CREATE_TASK: "createHour/CREATE_TASK",
  CREATE_TASK_SUCCESS: "createHour/CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "createHour/CREATE_TASK_FAILURE",
  GET_CUSTOMERS: "createHour/GET_CUSTOMERS",
  GET_CUSTOMERS_SUCCESS: "createHour/GET_CUSTOMERS_SUCCESS",
  GET_CUSTOMERS_FAILURE: "createHour/GET_CUSTOMERS_FAILURE",
  CREATE_PROJECT: "createHour/CREATE_PROJECT",
};
export const createHour = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_DIALOG: {
      const { weekDay } = action.payload;
      return {
        ...state,
        showDialog: !state.showDialog,
        weekDay,
      };
    }
    case types.CREATE_TASK:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_PROJECT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_TASK_SUCCESS:
      return {
        ...state,
        showDialog: false,
        formState: action.payload.formState,
        isLoading: false,
        error: null,
      };
    case types.CREATE_TASK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export const createHourByDate = (values) => {
  return async (dispatch, getState) => {
    const state = getState();
    const startDate = moment(state.filters.startDate).clone();
    const selectedWeekDay = state.createHour.weekDay;
    const selectedDate = startDate.day(selectedWeekDay);
    try {
      dispatch({
        type: types.CREATE_PROJECT,
      });
      const project = values.project;
      let newProjectId = values.project.value;
      if (project.__isNew__) {
        const customerResponse = await getCutomers();
        const projectrequest = {
          name: newProjectId,
          customer_id: customerResponse.data.entries[0].id,
        };
        await dispatch(createProject(projectrequest));
        const projectIds = getState().projects.ids;
        newProjectId = projectIds[projectIds.length - 1];
      }
      dispatch({
        type: types.CREATE_TASK,
      });
      const task = values.task;
      let newTaskId = values.task.value;
      if (task.__isNew__) {
        await dispatch(
          createTaskByProject({ name: task.label, project_id: newProjectId })
        );
        const taskIds = getState().tasksByProject.ids;
        newTaskId = taskIds[taskIds.length - 1];
      }
      const hour_type_id = values.hourType.value;
      let requestObject = {
        comment: values.comment,
        task_id: newTaskId,
        hour_type_id: hour_type_id,
      };
      if (values.durationType === "Amount") {
        requestObject.amount = "";
        if (values.hours) {
          requestObject.amount = values.hours;
        }
        if (values.minutes) {
          requestObject.amount = requestObject.amount + "." + values.minutes;
        }
        const { startTime, endTime } = getStartAndEndTimeFromAmount(
          selectedDate,
          values.hours,
          values.minutes
        );
        requestObject.start_time = startTime;
        requestObject.end_time = endTime;
      }
      //Check in / Check out - End time will always be selected
      else {
        const end = values.endTime.split(":");
        const endTimeHour = end[0];
        const endTimeMin = end[1];
        requestObject.start_time = selectedDate.clone();
        requestObject.end_time = selectedDate.set({
          hour: endTimeHour,
          minute: endTimeMin,
        });

        if (values.startTime) {
          const start = values.startTime.split(":");
          const startTimeHour = start[0];
          const startTimeMin = start[1];
          requestObject.start_time = selectedDate.clone().set({
            hour: startTimeHour,
            minute: startTimeMin,
          });
        }

        requestObject.amount = getAmountFromStartAndEndTime(
          requestObject.start_time,
          requestObject.end_time
        );
      }
      if (values.type === "week") {
        let responseHourid = [];
        let i;
        var dateStartEndObj = {
          startDate: [],
          endDate: [],
        };
        for (i = 1; i <= 5; i++) {
          dateStartEndObj.startDate.push(
            requestObject.start_time.clone().day(i)
          );
          dateStartEndObj.endDate.push(requestObject.end_time.clone().day(i));
          var request = {
            amount: requestObject.amount,
            comment: requestObject.comment,
            end_time: requestObject.end_time
              .clone()
              .day(i)
              .format("YYYY-MM-DDTHH:mm:ss"),
            hour_type_id: requestObject.hour_type_id,
            start_time: requestObject.start_time
              .clone()
              .day(i)
              .format("YYYY-MM-DDTHH:mm:ss"),
            task_id: requestObject.task_id,
          };

          await createHourByDateApi(request).then((response) => {
            responseHourid.push(response.data.id);
            requestObject.start_time.clone().day(1);
            requestObject.end_time.clone().day(1);
          });
          if (i === 5) {
            requestObject.project_id = newProjectId;
            requestObject.id = responseHourid;
            dispatch({
              type: hourListTypes.ADD_TASK_FOR_WEEK,
              payload: {
                task: requestObject,
                dateStartEndObj: dateStartEndObj,
              },
            });
          }
        }
      }
      if (values.type === "day") {
        request = {
          amount: requestObject.amount,
          comment: requestObject.comment,
          end_time: requestObject.end_time
            .clone()
            .format("YYYY-MM-DDTHH:mm:ss"),
          hour_type_id: requestObject.hour_type_id,
          start_time: requestObject.start_time
            .clone()
            .format("YYYY-MM-DDTHH:mm:ss"),
          task_id: requestObject.task_id,
        };

        const response = await createHourByDateApi(request);
        requestObject.id = response.data ? response.data.id : "";
        requestObject.project_id = parseInt(newProjectId, 10);
        dispatch({
          type: hourListTypes.ADD_TASK_BY_COLUMN,
          payload: {
            task: requestObject,
            columnId: getState().createHour.weekDay,
          },
        });
      }

      dispatch({
        type: types.CREATE_TASK_SUCCESS,
        payload: { formState: values },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.CREATE_TASK_FAILURE,
        payload: { error: message },
      });
    }
  };
};

const getCutomers = async (requestBody, dispatch) => {
  try {
    const response = await getCustomerApi(requestBody);
    return response;
  } catch (error) {
    let errorCode = getErrorCode(error);
    let message = i18next.t("Failure on authentication");
    if (errorCode) {
      if (errorCode === 401) {
        logout();
      }
      switch (errorCode) {
        case 401:
          message = i18next.t("Client is not logged in");
          break;
        case 403:
          message = i18next.t(
            "User without privileges to perform this operation"
          );
          break;
        case 500:
        default:
          message =
            "Bad Request. Something is wrong with the parameters or the request itself.";
          break;
      }
    }

    dispatch({
      type: types.GET_CUSTOMERS_FAILURE,
      payload: {
        error: message,
      },
    });
  }
};

const toggleDialog = (weekDay = null) => {
  return {
    type: types.TOGGLE_DIALOG,
    payload: { weekDay },
  };
};

const checkNewHour = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    function filterObj(keys, obj) {
      const newObj = {};
      obj.columns[state.createHour.weekDay].taskIds.map((key) => {
        if (obj.tasks[key].task_id === parseInt(data, 10)) {
          newObj.value = obj.tasks[key].task_name;
          newObj.id = obj.tasks[key].task_id;
        }
        return newObj;
      });

      return newObj;
    }
    const resp = filterObj("task_name", state.hourList.data);
    return resp.value ? true : false;
  };
};
const createHourByAmount = (request, day, amount) => {
  return async (dispatch, getState) => {
    const state = getState();
    const startDate = moment(state.filters.startDate).clone();
    const selectedDate = startDate.day(day);
    try {
      let requestObject = {
        task_id: request.task_id,
        amount: amount,
        hour_type_id: request.hour_type_id,
      };
      var hrs = 0;
      var minuts = 0;
      if (amount) {
        if (amount.toString().split(".")[0]) {
          hrs = amount.toString().split(".")[0];
        }
        if (amount.toString().split(".")[1]) {
          minuts = amount.toString().split(".")[1];
        }
        const { startTime, endTime } = getStartAndEndTimeFromAmount(
          selectedDate,
          hrs,
          minuts
        );
        requestObject.start_time = startTime;
        requestObject.end_time = endTime;
      }
      await createHourByDateApi(requestObject);
      request.flag = "view";
      dispatch({
        type: hourListOldTypes.UPDATE_TASK,
        payload: {
          task: request,
        },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }
      dispatch({
        type: types.CREATE_TASK_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
const createHourByTable = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const startDate = moment(state.filters.startDate).clone();
    try {
      dispatch({
        type: types.CREATE_PROJECT,
      });
      const project = data.project;
      let newProjectId = data.project.value;
      if (project.__isNew__) {
        const customerResponse = await getCutomers();
        const projectrequest = {
          name: newProjectId,
          customer_id: customerResponse.data.entries[0].id,
        };
        await dispatch(createProject(projectrequest));
        const projectIds = getState().projects.ids;
        newProjectId = projectIds[projectIds.length - 1];
      }
      dispatch({
        type: types.CREATE_TASK,
      });
      const task = data.task;
      let newTaskId = data.task.value;
      if (task.__isNew__) {
        await dispatch(
          createTaskByProject({ name: task.label, project_id: newProjectId })
        );
        const taskIds = getState().tasksByProject.ids;
        newTaskId = taskIds[taskIds.length - 1];
      }
      let requestObject = {
        task_id: newTaskId,

        hour_type_id: data.task_type.value,
      };
      let responseHourid = [];
      var i;
      for (i = 1; i <= 5; i++) {
        var selectedDate = startDate.clone().day(i);
        var weekDay = getWeekday(startDate.clone().day(i));
        requestObject.amount = data[weekDay.toLowerCase()];
        var hrs = 0;
        var minuts = 0;
        if (requestObject.amount) {
          if (requestObject.amount.toString().split(".")[0]) {
            hrs = requestObject.amount.toString().split(".")[0];
          }
          if (requestObject.amount.toString().split(".")[1]) {
            minuts = requestObject.amount.toString().split(".")[1];
          }
          const { startTime, endTime } = getStartAndEndTimeFromAmount(
            selectedDate,
            hrs,
            minuts
          );
          requestObject.start_time = startTime;
          requestObject.end_time = endTime;
        }
        var request = {
          comment: "Internal",
          amount: requestObject.amount,
          end_time: requestObject.end_time
            .clone()
            .day(i)
            .format("YYYY-MM-DDTHH:mm:ss"),
          hour_type_id: requestObject.hour_type_id,
          start_time: requestObject.start_time
            .clone()
            .day(i)
            .format("YYYY-MM-DDTHH:mm:ss"),
          task_id: requestObject.task_id,
        };
        if (requestObject.amount) {
          await createHourByDateApi(request).then((response) => {
            responseHourid.push(response.data.id);
          });
        }

        if (i === 5) {
          const hour = {
            id: data.task.value,
            hours_id: responseHourid,
            project_id: parseInt(data.project.value, 10),
            task_id: data.task.value,
            task_name: data.task.label,
            hour_type_id: data.task_type.value,
            hour_type_name: data.task_type.label,
            monday: data.monday,
            tuesday: data.tuesday,
            wednesday: data.wednesday,
            thursday: data.thursday,
            friday: data.friday,
            saturday: data.saturday,
            sunday: data.sunday,
            open: false,
            flag: "view",
            type: "day",
          };
          dispatch({
            type: hourListOldTypes.UPDATE_TASK,
            payload: {
              task: hour,
            },
          });
        }
      }
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.CREATE_TASK_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
export const actions = {
  toggleDialog,
  createHourByDate,
  checkNewHour,
  createHourByAmount,
  createHourByTable,
};
