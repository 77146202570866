import { getBaseUrl } from "../Utilities/api.config.js";
import { actions as authActions } from "../app/auth";
import { AUTHORIZED_CLIENT_TOKEN } from "../Utilities/appConstants";
const baseUrl = getBaseUrl();

export const core = {
  API: (
    method,
    url,
    baseType,
    commitType,
    rollbackType,
    data,
    metaArg,
    isClientToken
  ) => ({
    type: baseType,
    meta: {
      offline: {
        effect: {
          url: `${baseUrl}${url}`,
          headers: {
            "Cache-Control": "no-cache",
            Authorization: `Bearer ${
              authActions.isLoggedIn() && !isClientToken
                ? authActions.getAccessToken()
                : AUTHORIZED_CLIENT_TOKEN
            }`,
          },
          method,
          json: data ? data : null,
        },
        commit: { type: commitType, meta: metaArg },
        rollback: { type: rollbackType },
      },
    },
  }),
};
