import {
  getOrganizationApi,
  postRegisterApi,
  checkUserApi,
  checkCompanyApi,
  getAllPlans,
  deleteCompanyApi,
} from "../services/signUp";
import { getErrorObject, getErrorCode } from "../Utilities/api.config";
import i18next from "i18next";
//import CountryList from "react-select-country-list";

const intialState = {
  isLoading: false,
  data: {},
  isCompanyRegistered: false,
  isUserExist: false,
  isError: false,
  isCompanyExist: false,
  cardToken: {},
  socialData: {},
  clientSecret: null,
  error: null,
  registerError: false,
};
const types = {
  REGISTERING_USER: "signUp/REGISTERING_USER",
  REGISTERING_USER_SUCCESS: "signUp/REGISTERING_USER_SUCCESS",
  REGISTERING_USER_FAILURE: "signUp/REGISTERING_USER_FAILURE",
  GET_ORGANIZATION: "signUp/GET_ORGANIZATION",
  RESET_SIGNUP_STORE: "signUp/RESET_SIGNUP_STORE",
  GET_ORGANIZATION_SUCCESS: "signUp/GET_ORGANIZATION_SUCCESS",
  GET_ORGANIZATION_FAILURE: "signUp/GET_ORGANIZATION_FAILURE",
  GET_USER: "signUp/GET_USER",
  GET_USER_SUCCESS: "signUp/GET_USER_SUCCESS",
  GET_USER_FAILURE: "signUp/GET_USER_FAILURE",
  SERVER_ERROR: "signUp/SERVER_ERROR",
  GET_COMPANY: "signUp/GET_COMPANY",
  GET_COMPANY_SUCCESS: "signUp/GET_COMPANY_SUCCESS",
  GET_COMPANY_FAILURE: "signUp/GET_COMPANY_FAILURE",
  ADD_CARD: "signUp/ADD_CARD",
  ADD_CARD_SUCCESS: "signUp/ADD_CARD_SUCCESS",
  ADD_CARD_FAILURE: "signUp/ADD_CARD_FAILURE",
  SOCIAL_LOGIN_SUCCESS: "signUp/SOCIAL_LOGIN_SUCCESS",
  GET_PLANS: "signUp/GET_PLANS",
  GET_PLANS_SUCCESS: "signUp/GET_PLANS_SUCCESS",
  GET_PLANS_FAILURE: "signUp/GET_PLANS_FAILURE",
  DELETE_COMPANY: "signUp/DELETE_COMPANY",
  DELETE_COMPANY_SUCCESS: "signUp/DELETE_COMPANY_SUCCESS",
  DELETE_COMPANY_FAILURE: "signUp/DELETE_COMPANY_FAILURE",
};

export const signUp = (state = intialState, action) => {
  switch (action.type) {
    case types.RESET_SIGNUP_STORE:
      return {
        ...state,
        isLoading: false,
        data: {},
        isCompanyRegistered: false,
        isUserExist: false,
        isError: false,
        isCompanyExist: false,
        cardToken: {},
        socialData: {},
        error: null,
      };
    case types.GET_ORGANIZATION:
      return {
        ...state,
        error: null,
      };
    case types.GET_ORGANIZATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isError: false,
        isCompanyRegistered: true,
      };
    }
    case types.GET_ORGANIZATION_FAILURE: {
      //const { error } = action.payload;
      return {
        ...state,
        isCompanyRegistered: false,
        isCompanyExist: false,
      };
    }
    case types.GET_USER: {
      return {
        ...state,

        error: null,
      };
    }
    case types.GET_USER_SUCCESS: {
      return {
        ...state,
        isUserExist: true,
        isError: true,
      };
    }
    case types.SERVER_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isError: true,
      };
    }
    case types.GET_USER_FAILURE: {
      return {
        ...state,
        isUserExist: false,
        isError: false,
        isLoading: false,
      };
    }
    case types.GET_COMPANY: {
      return {
        ...state,
        error: null,
      };
    }
    case types.GET_COMPANY_SUCCESS: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isError: true,
        isCompanyExist: true,
      };
    }
    case types.GET_COMPANY_FAILURE: {
      return {
        ...state,
        error: null,
        isError: false,
        isCompanyExist: false,
      };
    }
    case types.ADD_CARD:
      return {
        ...state,
        isLoading: true,
      };
    case types.ADD_CARD_SUCCESS: {
      const { cardToken } = action.payload;
      return {
        ...state,
        error: null,
        isLoading: false,
        cardToken,
      };
    }
    case types.ADD_CARD_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case types.SOCIAL_LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        socialData: data,
      };
    }
    case types.REGISTERING_USER: {
      return {
        ...state,
        isLoading: true,
        error: null,
        registerError: false,
      };
    }
    case types.REGISTERING_USER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isLoading: false,
      };
    }
    case types.REGISTERING_USER_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
        registerError: true,
      };
    }
    case types.GET_PLANS: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.GET_PLANS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        signupPlans: data,
        isLoading: false,
      };
    }
    case types.GET_PLANS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    case types.DELETE_COMPANY: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.DELETE_COMPANY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.DELETE_COMPANY_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error: error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

const verifyCard = (stripe, onSubmit, values) => {
  return (dispatch) => {
    dispatch({ type: types.ADD_CARD });
    stripe.createToken().then((payload) => {
      if (payload.error) {
        dispatch({
          type: types.ADD_CARD_FAILURE,
          payload: { error: null },
        });
      } else {
        dispatch({
          type: types.ADD_CARD_SUCCESS,
          payload: { cardToken: payload },
        });
        onSubmit(values);
      }
    });
  };
};

const resetStore = () => {
  return async (dispatch, getState) => {
    dispatch({ type: types.RESET_SIGNUP_STORE });
  };
};

const getOrganization = (organizationNumber, changeForm) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_ORGANIZATION });
      const response = await getOrganizationApi(organizationNumber);
      dispatch({
        type: types.GET_ORGANIZATION_SUCCESS,
        payload: { data: response.data },
      });
      const organizationData = response.data;
      const { forretningsadresse } = organizationData;
      const address = `${forretningsadresse.adresse}, ${forretningsadresse.postnummer}`;
      const countryCode = organizationData.forretningsadresse.landkode;
      const country = countryCode;
      updateCompanyForm(
        {
          ...organizationData,
          address,
          country,
        },
        changeForm
      );
    } catch (error) {
      let { message } = getErrorObject(error);
      if (message.includes("40")) {
        message = null;
      }
      //updateCompanyForm({ country: "NO" }, changeForm);
      dispatch({
        type: types.GET_ORGANIZATION_FAILURE,
        payload: { error: message },
      });
    }
  };
};

const updateCompanyForm = (values, changeForm) => {
  const { navn, organisasjonsnummer, address, country } = values;

  if (values.navn) {
    changeForm("company.name", navn);
    changeForm("company.organization_number", organisasjonsnummer);
    changeForm("company.address", address);
    changeForm("company.country", country);
  }
};

const saveSocialData = (data) => {
  return {
    type: types.SOCIAL_LOGIN_SUCCESS,
    payload: { data },
  };
};

const checkUser = (requestBody) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_USER });
      await checkUserApi(requestBody);
      dispatch({ type: types.GET_USER_SUCCESS });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Authentication Failed!");
      if (errorCode) {
        switch (errorCode) {
          case 404:
            message = i18next.t("User not exist");
            dispatch({
              type: types.GET_USER_FAILURE,
              payload: { error: message },
            });
            return message;
          case 500:
          case 401:
          default:
            message = i18next.t("Failure on authentication");
            dispatch({
              type: types.SERVER_ERROR,
              payload: { error: message },
            });
            break;
        }
      }
    }
  };
};

const checkCompany = (requestBody) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_COMPANY });
      const response = await checkCompanyApi(requestBody);
      let message =
        response.status && response.status === 200
          ? "This company already exists in our system"
          : response.data || response.data.message
          ? response.data.message
          : "This company already exists in our system";
      dispatch({
        type: types.GET_COMPANY_SUCCESS,
        payload: { error: message },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Authentication Failed!");
      if (errorCode) {
        switch (errorCode) {
          case 404:
            dispatch({
              type: types.GET_COMPANY_FAILURE,
              payload: { error: message },
            });
            break;
          case 500:
          case 401:
          default:
            message = i18next.t("Failure on authentication");
            dispatch({
              type: types.SERVER_ERROR,
              payload: { error: message },
            });
            break;
        }
      }
    }
  };
};

const registerUser = (requestBody, callback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.REGISTERING_USER });
      const response = await postRegisterApi(requestBody);
      dispatch({
        type: types.REGISTERING_USER_SUCCESS,
        payload: {
          data: response.data,
        },
      });
      callback(response.data);
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Authentication Failed!");
      if (errorCode) {
        switch (errorCode) {
          case 500:
            message = i18next.t("Failure on authentication");
            break;
          case 400:
            message = i18next.t("Failure on authentication");
            break;
          case 401:
            message = i18next.t("Authentication Failed!");
            break;
          case 403:
            message = i18next.t("Access Restrictions");
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          default:
            message = i18next.t("Failure on authentication");
            break;
        }
      }
      if (message === "Your card was declined.") {
        dispatch({
          type: types.REGISTERING_USER_SUCCESS,
          payload: {
            data: { payment_intent: "requires_payment_method" },
          },
        });
        callback({ payment_intent: "requires_payment_method" });
      } else {
        dispatch({
          type: types.REGISTERING_USER_FAILURE,
          payload: { error: message },
        });
      }
    }
  };
};

const getAllPlanDetails = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_PLANS });
      const response = await getAllPlans();
      dispatch({
        type: types.GET_PLANS_SUCCESS,
        payload: { data: response.data },
      });
    } catch (error) {
      let { message } = getErrorObject(error);
      if (message.includes("40")) {
        message = null;
      }
      dispatch({
        type: types.GET_PLANS_FAILURE,
        payload: { error: message },
      });
    }
  };
};

const deleteCompany = (token) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.DELETE_COMPANY });
      const response = await deleteCompanyApi(token);
      dispatch({
        type: types.DELETE_COMPANY_SUCCESS,
        payload: { data: response.data },
      });
    } catch (error) {
      let { message } = getErrorObject(error);
      dispatch({
        type: types.DELETE_COMPANY_FAILURE,
        payload: { error: message },
      });
    }
  };
};

export const actions = {
  registerUser,
  verifyCard,
  saveSocialData,
  getOrganization,
  resetStore,
  checkUser,
  checkCompany,
  getAllPlanDetails,
  deleteCompany,
};
