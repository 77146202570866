import { deleteHourApi } from "../../services/hours";
import { removeHourFromWeek } from "../../reducers/hours/hourList";
import { actions as modalActions } from "../../reducers/common/modal";
const initialState = {
  isLoading: false,
  deleteId: null,
  error: null,
};
const types = {
  DELETE_HOUR: "deleteHour/DELETE_HOUR",
  DELETE_HOUR_SUCCESS: "deleteHour/DELETE_HOUR_SUCCESS",
  DELETE_HOUR_FAILURE: "deleteHour/DELETE_HOUR_FAILURE",
};
export const deleteHour = (state = initialState, action) => {
  switch (action.type) {
    case types.DELETE_HOUR: {
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    }
    case types.DELETE_HOUR_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case types.DELETE_HOUR_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    default:
      return state;
  }
};

const deleteHourById = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.DELETE_HOUR,
      });
      const { hourId, columnId, index } = getState().modal.modalProps;
      await deleteHourApi(hourId);

      dispatch(removeHourFromWeek(hourId, columnId, index));
      dispatch(modalActions.hideModal());
      dispatch({ type: types.DELETE_HOUR_SUCCESS });
    } catch (error) {
      dispatch({
        type: types.DELETE_HOUR_FAILURE,
        payload: { error },
      });
    }
  };
};
export const actions = {
  deleteHourById,
};
export default deleteHour;
