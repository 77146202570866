import React from "react";
import spinner from "../../../assets/images/spinner.svg";
import "../common.css";
export const OverlayIndicator = ({ isLoading }) => {
  return (
    isLoading && (
      <div className="overlay-div" style={{ zIndex: 1001 }}>
        <img src={spinner} className="overlay-spinner" alt="Loading..." />
      </div>
    )
  );
};
