//@flow
const initialState = {
  modalType: null,
  modalProps: {},
};
const types = {
  SHOW_MODAL: "modal/SHOW_MODAL",
  HIDE_MODAL: "modal/HIDE_MODAL",
};
export const modal = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL: {
      const { modalType, modalProps } = action.payload;
      return {
        modalType: modalType,
        modalProps: modalProps,
      };
    }
    case types.HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
const showModal = (modalType, modalProps) => {
  return {
    type: types.SHOW_MODAL,
    payload: { modalType, modalProps },
  };
};
const hideModal = () => {
  return {
    type: types.HIDE_MODAL,
  };
};
export const actions = {
  showModal,
  hideModal,
};
