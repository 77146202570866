export const APP_ROUTES = {
  login: "/login",
  signUp: "/signUp",
  hours: "/hours",
  forgotPassword: "/forgotPassword",
  confirmed: "/confirmed",
  resetPassword: "/reset-password/:reset_password_token",
};
export const errorMessages = {
  required: "Field is required",
  passwordMismatch: "Password does not match",
  invalidPassword:
    "Your password must be at least 6 characters long, contain at least one number and have a mixture of uppercase and lowercase letters.",
  invalid: "Please enter a valid value",
  invalidEmail: "Please enter a valid email",
  invalidPhone: "Please enter a valid phone number",
  user_exist: "A user with this email already exists!",
  invalidOrganisationNumber:
    "Organisation number must contain minimum 9 characters",
  duplicateEmail: "Duplicate email",
  invalidBirthID: "Birth ID must contain 11 digits",
  invalidBankAccountNumber: "Bank Account Number must contain 11 digits",
  invalidHoliday: "Holiday must be less than 366 days",
  invalidPercentage: "Please enter value between 1 to 100",
  invalidSalary: "Please enter value between 1 to 999999",
};

export const STRIPE_KEY =
  process.env.NODE_ENV === "development"
    ? "pk_test_3UQAeb1BdMpdEhM1a3TImLyy"
    : "pk_live_UfjR8YqFfkpJMct91s0EiDdK";

export const AUTHORIZED_CLIENT_TOKEN =
  "dc07fe292b71c8a71d893f05a8a8441a95d0913ec97dcff6060f17bd78e216b241d3aebac1567171ce35cd7483a77017d866b352d122ebebd0d21a04a1ea4902";
export const BUGSNAG_CLIENT = "71e9151cc1a2355839713bfa1f09d720";
export const amount_PerUser_PerMonth = "9.99";
export const GOOGLE_MAPS_KEY = "AIzaSyAZvi7HT-qCFCXJtoW31p2UllIBqaNsWJY";
export const AZURE_AD_CLIENT_ID = "ac1071a5-474d-4367-8307-51e39b18c71a";
export const ANDROID_APP_LINK =
  "https://play.google.com/store/apps/details?id=com.visualregistration.v2";
export const IPHONE_APP_LINK =
  "https://apps.apple.com/us/app/visual-registration-system/id1448200919";
export const APP_DEEP_LINK = "https://vrsv2.page.link/NLtk";

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_SUB_ITEM: 20,
  NAVBAR_ITEM_HORIZONTAL: 20,
};
