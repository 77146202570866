import moment from "moment";
import React from "react";
import nb from "date-fns/locale/nb";
import enGB from "date-fns/locale/en-GB";
import de from "date-fns/locale/de";
import ro from "date-fns/locale/ro";
import sv from "date-fns/locale/sv";
import { registerLocale } from "react-datepicker";

export const normalize = ({ data = [], key }) => {
  let obj = {};
  let ids = [];
  if (data && Array.isArray(data)) {
    data.forEach((element) => {
      obj[element[key]] = element;
      ids.push(element[key]);
    });
    return { data: obj, ids };
  }
};

export const normalizeForReactSelect = ({ data }) => {
  return data.map((entry) => ({
    value: entry.id.toString(),
    label: entry.name,
  }));
};

export const getStartAndEndOfWeek = () => {
  const momentObj = moment();
  return {
    start_time: momentObj.startOf("week"),
    end_time: momentObj.endOf("week"),
  };
};

export const getStartAndEndTimeFromAmount = (
  selectedDate,
  hours = 0,
  minutes = 0,
  start_Time
) => {
  // const momentObj = moment(selectedDate);
  let startTime, endTime;
  if (start_Time && parseFloat(start_Time) > 0) {
    startTime = moment(selectedDate)
      .startOf("day")
      .add(start_Time.split(`:`)[0], "h")
      .add(start_Time.split(`:`)[1], "m");
    endTime = moment(startTime).add(hours, "h").add(minutes, "m");
  } else {
    startTime = moment(selectedDate).startOf("day");
    endTime = moment(selectedDate).add(hours, "h").add(minutes, "m");
  }
  return {
    startTime,
    endTime,
  };
};

export const getAmountFromStartAndEndTime = (startTime, endTime) => {
  const diffTime = moment(endTime).diff(moment(startTime));
  return moment.duration(diffTime).asHours();
};

export const getDurationFromStartAndEndTime = (startTime, endTime) => {
  const diffTime = moment(endTime).diff(moment(startTime));
  const duration = moment.duration(diffTime);

  return duration.hours() + "." + duration.minutes();
};

export const getWeekday = (startDate) => {
  const dayNumber = moment(startDate).isoWeekday();
  switch (dayNumber) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 7:
      return "Sunday";
    default:
      break;
  }
};

export const getDateOfISOWeek = (newWeekday) => {
  return moment().day(newWeekday);
};

export const getAmountFromFloatDigit = (inputDiff) => {
  var minuts = "";
  var hours = "";
  hours = inputDiff.toString().split(".")[0];
  minuts = inputDiff.toString().split(".")[1];
  if (parseInt(minuts, 10) > 6) {
    if (parseInt(minuts, 10) < 10) {
      hours =
        parseInt(inputDiff.toString().split(".")[0], 10) +
        Math.floor(
          (parseInt(inputDiff.toString().split(".")[1], 10) * 10) / 60
        );
      minuts = (parseInt(inputDiff.toString().split(".")[1], 10) * 10) % 60;
      if (minuts < 10) {
        minuts = 0 + "" + minuts;
      }
    }
    if (parseInt(minuts, 10) > 60) {
      hours =
        parseInt(inputDiff.toString().split(".")[0], 10) +
        Math.floor(parseInt(inputDiff.toString().split(".")[1], 10) / 60);
      minuts = parseInt(inputDiff.toString().split(".")[1], 10) % 60;
      if (minuts < 10) {
        minuts = 0 + "" + minuts;
      }
    }
  } else {
    minuts = minuts + 0;
  }
  if (isNaN(minuts)) {
    minuts = 0;
  }
  return hours + "." + minuts;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const AnimationLoader = () => {
  return (
    <div>
      <div className={"row border mx-1 mb-1 py-3 text-center"}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
            <div className="col-md-4">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
            <div className="col-md-4 text-right">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
          </div>
        </div>
      </div>
      <div className={"row border mx-1 mb-1 py-3 text-center"}>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
            <div className="col-md-4">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
            <div className="col-md-4 text-right">
              <div className="anim-row animated-background mb-1" />
              <div className="anim-row animated-background" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // do not reject to prevent error in bugsnag
            // reject('maximum retries exceeded');
            // reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const getLocale = (language) => {
  // In en-ca locale, Sunday is first day of week so en-ca is used for en (English)
  if (language === "nb") {
    return "nb";
  } else if (language === "de") {
    return "de";
  } else if (language === "ro") {
    return "ro";
  } else if (language === "sv") {
    return "sv";
  } else {
    return "en-ca";
  }
};

export const isWeekStartWithMonday = (language) => {
  return language === "en" ? false : true;
};

export const getTimeFormat = (language) => {
  if (language === "en") {
    return "h:mm a";
  } else {
    return "H:mm"; // HH:mm
  }
};

export const getDateAndDayFormat = (language) => {
  if (language === "en") {
    return "MM.DD.YY, ddd";
  } else {
    return "DD.MM.YY, ddd";
  }
};

export const getDateAndTimeFormat = (language) => {
  if (language === "en") {
    return "MM.DD.YY, h:mm a";
  } else {
    return "DD.MM.YY, H:mm";
  }
};

export const getDateFormat = (language) => {
  if (language === "en") {
    return "MM.DD.YY";
  } else {
    return "DD.MM.YY";
  }
};

export const getLocaleDateFns = (language) => {
  // The DatePicker for repeat date uses date-fns and it supports en instead of en-ca
  if (language === "nb") {
    return "nb";
  } else if (language === "de") {
    return "de";
  } else if (language === "ro") {
    return "ro";
  } else if (language === "sv") {
    return "sv";
  } else {
    return "en";
  }
};

export const registerDateFnsLocale = (language) => {
  if (language === "nb") {
    return registerLocale("nb", nb);
  } else if (language === "de") {
    return registerLocale("de", de);
  } else if (language === "ro") {
    return registerLocale("ro", ro);
  } else if (language === "sv") {
    return registerLocale("sv", sv);
  } else {
    return registerLocale("en-GB", enGB);
  }
};
