import { post } from "../Utilities/api.config.js";
import { AUTHORIZED_CLIENT_TOKEN } from "../Utilities/appConstants";

export const logoutApi = (params) => {
  const request = {
    subUrl: `/oauth/revoke`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    data: params,
  };
  return post(request);
};
