import { types as hourListTypes } from "../../reducers/hours/hourList";
import { types as hourListOldTypes } from "../../reducers/hours/hourListOld";
import { types as ProjectTypes } from "../../reducers/hours/projects";
import { createTaskByProject } from "../../reducers/hours/tasksByProject";
import { createProject } from "../../reducers/hours/projects";
import { getErrorCode, logout } from "../../Utilities/api.config";
import { getCustomerApi, updateHourByDateApi } from "../../services/hours";
import {
  getStartAndEndTimeFromAmount,
  getAmountFromStartAndEndTime,
} from "../../Utilities/utils";
import i18next from "i18next";
const initialState = {
  showDialog: false,
  isLoading: false,
  weekDay: null,
  task: {},
  error: null,
};
const types = {
  GET_TASK_DATA: "editHour/GET_TASK_DATA",
  TOGGLE_DIALOG: "editHour/TOGGLE_DIALOG",
  TASK_LOADING: "editHour/TASK_LOADING",
  EDIT_TASK: "editHour/EDIT_TASK",
  EDIT_TASK_SUCCESS: "editHour/EDIT_TASK_SUCCESS",
  EDIT_TASK_FAILURE: "editHour/EDIT_TASK_FAILURE",
};
export const editHour = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_DIALOG: {
      const { task, weekDay } = action.payload;
      return {
        ...state,
        showDialog: !state.showDialog,
        weekDay,
        task,
        isLoading: false,
      };
    }
    case types.EDIT_TASK:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_TASK_DATA:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.TASK_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.EDIT_TASK_SUCCESS:
      return {
        ...state,
        showDialog: false,
        isLoading: false,
      };
    case types.EDIT_TASK_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export const editHourByDate = (values, task) => {
  return async (dispatch, getState) => {
    const state = getState();

    const startDate = state.filters.startDate.clone();
    const selectedWeekDay = state.editHour.weekDay;
    const selectedDate = startDate.day(selectedWeekDay);
    try {
      dispatch({
        type: types.EDIT_TASK,
      });
      const project = values.project;
      let newProjectId = values.project.value;
      if (project.__isNew__) {
        const customerResponse = await getCustomers();
        const projectrequest = {
          name: newProjectId,
          customer_id: customerResponse.data.entries[0].id,
        };
        await dispatch(createProject(projectrequest));
        const projectIds = getState().projects.ids;
        newProjectId = projectIds[projectIds.length - 1];
      }
      const task = values.task;
      let newTaskId = values.task.value;
      if (task.__isNew__) {
        await dispatch(
          createTaskByProject({
            name: task.label,
            project_id: newProjectId,
          })
        );
        const taskIds = getState().tasksByProject.ids;
        newTaskId = taskIds[taskIds.length - 1];
      }

      const hour_type_id = values.hourType.value;
      let requestObject = {
        comment: values.comment,
        task_id: newTaskId,
        hour_type_id: hour_type_id,
      };

      if (values.durationType === "amount") {
        requestObject.amount = "";
        if (values.hours) {
          requestObject.amount = requestObject.amount + values.hours + "h ";
        }
        if (values.minutes) {
          requestObject.amount = requestObject.amount + values.minutes + "m ";
        }
        const { startTime, endTime } = getStartAndEndTimeFromAmount(
          selectedDate,
          values.hours,
          values.minutes
        );
        requestObject.start_time = startTime;
        requestObject.end_time = endTime;
      }
      //Check in / Check out - End time will always be selected
      else {
        const end = values.endTime.split(":");
        const endTimeHour = end[0];
        const endTimeMin = end[1];
        requestObject.start_time = selectedDate.clone();
        requestObject.end_time = selectedDate.set({
          hour: endTimeHour,
          minute: endTimeMin,
        });

        if (values.startTime) {
          const start = values.startTime.split(":");
          const startTimeHour = start[0];
          const startTimeMin = start[1];
          requestObject.start_time = selectedDate.clone().set({
            hour: startTimeHour,
            minute: startTimeMin,
          });
        }

        requestObject.amount = getAmountFromStartAndEndTime(
          requestObject.start_time,
          requestObject.end_time
        );
      }

      values.duration = requestObject.amount;
      await updateHourByDateApi(values.id, requestObject);

      dispatch({
        type: hourListTypes.UPDATE_TASK,
        payload: {
          task: values,
        },
      });
      dispatch({
        type: types.EDIT_TASK_SUCCESS,
        payload: {},
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.EDIT_TASK_FAILURE,
        payload: { error: message },
      });
    }
  };
};
export const editHourAmount = (id, task, requestObj) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.EDIT_TASK,
      });
      await updateHourByDateApi(id, requestObj);

      dispatch({
        type: hourListOldTypes.UPDATE_TASK,
        payload: {
          task,
        },
      });
      dispatch({
        type: types.EDIT_TASK_SUCCESS,
        payload: {},
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 422:
            message =
              error.response.data.message || "Something missing. Please check";
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.EDIT_TASK_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
const getCustomers = async (requestBody, dispatch) => {
  try {
    const response = await getCustomerApi(requestBody);
    return response;
  } catch (error) {
    let errorCode = getErrorCode(error);
    let message = i18next.t("Failure on authentication");
    if (errorCode) {
      if (errorCode === 401) {
        logout();
      }
      switch (errorCode) {
        case 401:
          message = i18next.t("Client is not logged in");
          break;
        case 403:
          message = i18next.t(
            "User without privileges to perform this operation"
          );
          break;
        case 500:
        default:
          message =
            "Bad Request. Something is wrong with the parameters or the request itself.";
          break;
      }
    }

    dispatch({
      type: types.GET_PROJECTS_FAILURE,
      payload: {
        error: message,
      },
    });
  }
};
const toggleDialog = (task = null, column) => {
  return async (dispatch, getState) => {
    try {
      if (task) {
        dispatch({
          type: types.GET_TASK_DATA,
        });
        task.hourType = {
          value: task.hour_type_id.toString(),
          label: task.hour_type_name,
        };
        task.task = {
          value: task.task_id.toString(),
          label: task.task_name,
        };
        task.project = {
          value: task.project_id.toString(),
          label: task.project_name,
        };
        task.durationType = "amount";
        dispatch({
          type: ProjectTypes.SELECT_PROJECT,
          payload: {
            selected: task.project,
          },
        });
      }
      dispatch({
        type: types.TOGGLE_DIALOG,
        payload: { task, weekDay: column },
      });
    } catch (error) {
      dispatch({
        type: types.CREATE_TASK_FAILURE,
        payload: { error },
      });
    }
  };
};
const checkNewHour = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    function filterObj(keys, obj) {
      const newObj = {};
      obj.columns[state.editHour.weekDay].taskIds.map((key) => {
        if (obj.tasks[key].task_id === parseInt(data, 10)) {
          newObj.value = obj.tasks[key].task_name;
          newObj.id = obj.tasks[key].task_id;
        }
        return newObj;
      });

      return newObj;
    }
    const resp = filterObj("task_name", state.hourList.data);
    return resp.value ? true : false;
  };
};

export const actions = {
  toggleDialog,
  editHourByDate,
  editHourAmount,
  checkNewHour,
};
