const getLanguage = () => {
  let language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : navigator.language;
  if (language === "nn" || language === "no" || language === "nb") {
    language = "nb";
  } else if (
    language === "en" ||
    language === "en-GB" ||
    language === "en-US" ||
    language === "en-CA"
  ) {
    language = "en";
  } else if (
    language === "de" ||
    language === "de-AT" ||
    language === "de-CH" ||
    language === "de-DE" ||
    language === "de-LI" ||
    language === "de-LU"
  ) {
    language = "de";
  } else if (language === "ro" || language === "ro-RO") {
    language = "ro";
  } else if (
    language === "sv" ||
    language === "sv-FI" ||
    language === "sv-SE"
  ) {
    language = "sv";
  } else {
    language = "en";
  }

  return language;
};

export default getLanguage;
