import createReducer from "../createReducer";
import * as types from "../../actions/types";
import { logout } from "../../Utilities/api.config";

const initialState = {
  isLoading: false,
  error: null,
  rulesLoading: false,
  rulesSuccess: null,
  rulesError: null,
  createRuleResponse: null,
};

export const rulesReducer = createReducer(initialState, {
  [types.GET_RULES](state, action) {
    return Object.assign({}, state, {
      ...state,
      rulesLoading: true,
      error: null,
    });
  },
  [types.GET_RULES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      rulesList: action.payload,
      rulesLoading: false,
    });
  },
  [types.GET_RULES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      rulesLoading: false,
    });
  },
  [types.CREATE_RULE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      createRuleResponse: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.CREATE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      createRuleResponse: action.payload,
      rulesError: null,
    });
  },
  [types.CREATE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      rulesError: action.payload,
      createRuleResponse: null,
      isLoading: false,
    });
  },
  [types.UPDATE_RULE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.UPDATE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      rulesSuccess: action.payload,
      rulesError: null,
    });
  },
  [types.UPDATE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      rulesError: action.payload,
      rulesSuccess: null,
      isLoading: false,
    });
  },
  [types.DELETE_RULE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      rulesSuccess: null,
      rulesError: null,
    });
  },
  [types.DELETE_RULE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      rulesSuccess: action.payload,
      rulesError: null,
    });
  },
  [types.DELETE_RULE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      rulesError: action.payload,
      rulesSuccess: null,
      isLoading: false,
    });
  },
});
