import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { rootReducer } from "./rootReducer";
import { offline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
const createHistory = require("history").createBrowserHistory;
export const history = createHistory();
const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  offline(offlineConfig),
  ...enhancers
);

const store = createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
);
// let tempState = {};
// if (initialState) {
//     tempState = initialState;
// }
// store.subscribe(
//         if (tempState !== store.getState().localStore) {
//     saveState({
//         localStore: store.getState().localStore
//     });
//     tempState = store.getState().localStore;
// }
// );
export default store;
