import { combineReducers } from "redux";
import { signUp } from "../reducers/signUp";
import { signIn } from "../reducers/signIn";
import { logOut } from "../reducers/logout";
import { stripeReducer } from "../reducers/stripe";
import { profile } from "../reducers/new/profile";
import { commonReducer } from "../reducers/new/common";
import { departmentReducer } from "../reducers/new/department";
import { hourReducer } from "../reducers/new/hour";
import { companyReducer } from "../reducers/new/company";
import { reportReducer } from "../reducers/new/report";
import { devianceReducer } from "../reducers/new/deviance";
import { categoryReducer } from "../reducers/new/category";
import { hmskTemplateReducer } from "../reducers/new/hmskTemplate";
import { hmskCheckListReducer } from "../reducers/new/hmskCheckList";
import { rulesReducer } from "../reducers/new/rules";
import {
  hourList,
  hourListOld,
  createHour,
  editHour,
  filters,
  projects,
  taskTypesByTask,
  tasksByProject,
  deleteHour,
} from "../reducers/hours";
import { modal } from "../reducers/common/modal";
import { task } from "../reducers/task";
import { projectReducer } from "../reducers/projects";
import { customer } from "../reducers/customer";
import { hourTypes } from "../reducers/hourTypes";
import { planningReducer } from "../reducers/planning";
import { addonTypes } from "../reducers/addonTypes";
import { geofenceReducer } from "../reducers/geoFence";
import { userGroupsReducer } from "../reducers/userGroups";
import { schedulesReducer } from "../reducers/schedules";
import { holidayReducer } from "../reducers/holidays";
import { dashboardReducer } from "../reducers/dashboard";
import { itemReducer } from "../reducers/item";

const appReducer = combineReducers({
  signUp,
  signIn,
  logOut,
  stripeReducer,
  deleteHour,
  filters,
  createHour,
  taskTypesByTask,
  editHour,
  modal,
  hourList,
  hourListOld,
  projects,
  tasksByProject,
  projectReducer,
  profile,
  commonReducer,
  departmentReducer,
  hourReducer,
  task,
  customer,
  hourTypes,
  companyReducer,
  reportReducer,
  devianceReducer,
  categoryReducer,
  hmskTemplateReducer,
  hmskCheckListReducer,
  planningReducer,
  rulesReducer,
  addonTypes,
  geofenceReducer,
  userGroupsReducer,
  holidayReducer,
  schedulesReducer,
  dashboardReducer,
  itemReducer,
});
export const rootReducer = (state, action) => {
  if (action.type === "logout/POST_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
