import createReducer from "../createReducer";
import * as types from "../../actions/types";
import { logout } from "../../Utilities/api.config";

const initialState = {
  isLoading: false,
  devianceDataLoading: false,
  successMessage: null,
  errorMessage: null,
  deviancesList: {},
  devianceData: {},
};

export const devianceReducer = createReducer(initialState, {
  [types.ADD_DEVIANCE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.ADD_DEVIANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.ADD_DEVIANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },
  [types.GET_DEVIANCES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
    });
  },
  [types.GET_DEVIANCES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      deviancesList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEVIANCES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_DEVIANCE](state, action) {
    return Object.assign({}, state, {
      ...state,
      devianceDataLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.GET_DEVIANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      devianceData: action.payload,
      devianceDataLoading: false,
    });
  },
  [types.GET_DEVIANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      devianceDataLoading: false,
    });
  },
  [types.UPDATE_DEVIANCE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.UPDATE_DEVIANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.UPDATE_DEVIANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_DEVIANCE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.DELETE_DEVIANCE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.DELETE_DEVIANCE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
});
