import { getPlansApi } from "../services/stripe";
import { core } from "../actions/core";

const intialState = {
  isLoading: false,
  allPlans: null,
  data: null,
  subscription: null,
  error: null,
};

const types = {
  GET_PLANS: "stripe/GET_PLANS",
  GET_PLANS_SUCCESS: "stripe/GET_PLANS_SUCCESS",
  GET_PLANS_FAILURE: "stripe/GET_PLANS_FAILURE",
  GET_STRIPE_SUBSCRIPTION: "stripe/STRIPE_SUBSCRIPTION",
  GET_STRIPE_SUBSCRIPTION_SUCCESS: "stripe/GET_STRIPE_SUBSCRIPTION_SUCCESS",
  GET_STRIPE_SUBSCRIPTION_FAILURE: "stripe/GET_STRIPE_SUBSCRIPTION_FAILURE",
};

export const stripeReducer = (state = intialState, action) => {
  switch (action.type) {
    case types.GET_PLANS:
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    case types.GET_PLANS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allPlans: action.payload.data,
        error: null,
      };
    }
    case types.GET_PLANS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        data: null,
        error: action.payload.error,
      };
    }
    case types.GET_STRIPE_SUBSCRIPTION:
      return {
        isLoading: true,
        subscription: null,
        error: null,
      };
    case types.GET_STRIPE_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        subscription: action.payload,
        error: null,
      };
    }
    case types.GET_STRIPE_SUBSCRIPTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    }
    default:
      return state;
  }
};

const getPlans = () => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.GET_PLANS });
      const response = await getPlansApi();
      dispatch({
        type: types.GET_PLANS_SUCCESS,
        payload: {
          data: response.data,
        },
      });
    } catch (error) {
      let message = "Failure in getting Stripe plans";
      dispatch({
        type: types.GET_PLANS_FAILURE,
        payload: { error: message },
      });
    }
  };
};

const getSubscription = () => {
  return core.API(
    "GET",
    `/company/subscription`,
    types.GET_STRIPE_SUBSCRIPTION,
    types.GET_STRIPE_SUBSCRIPTION_SUCCESS,
    types.GET_STRIPE_SUBSCRIPTION_FAILURE
  );
};

export const actions = {
  getPlans,
  getSubscription,
};
