import createReducer from "./createReducer";
import * as types from "../actions/types";
import { logout } from "../Utilities/api.config";

const initialState = {
  isLoading: false,
  data: {},
  addonTypesList: {},
  error: null,
  succMsg: null,
  errMsg: null,
  optionalAddonTypes: {},
};

export const addonTypes = createReducer(initialState, {
  [types.GET_ADDON_TYPES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonTypesList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_ADDON_TYPES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_ADDON_TYPES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_ADDON_TYPES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      optionalAddonTypes: {},
      error: null,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      optionalAddonTypes: action.payload,
      isLoading: false,
    });
  },
  [types.GET_OPTIONAL_ADDON_TYPES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },

  [types.SET_OPTIONAL_ADDON_TYPE](state, action) {
    return Object.assign({}, state, {
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_ADDON_TYPE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.SET_OPTIONAL_ADDON_TYPE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
