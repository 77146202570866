import { hoursListApi, updateHourByDateApi } from "../../services/hours";
import { actions as hourListOldAction } from "../../reducers/hours/hourListOld";
import { getProjectsApi } from "../../services/projects";
import { getErrorCode, logout } from "../../Utilities/api.config";
import moment from "moment";
import {
  capitalize,
  getWeekday,
  getDateOfISOWeek,
  getAmountFromFloatDigit,
} from "../../Utilities/utils";
import i18next from "i18next";

export const types = {
  UPDATE_COLUMN: "hourList/UPDATE_COLUMN",
  UPDATE_COLUMN_SUCCESS: "hourList/UPDATE_COLUMN_SUCCESS",
  UPDATE_COLUMN_FAILURE: "hourList/UPDATE_COLUMN_FAILURE",
  DROP_TASK_SUCCESS_SAME_COLUMN: "hourList/DROP_TASK_SUCCESS_SAME_COLUMN",
  DROP_TASK_START: "hourList/DROP_TASK_START",
  DROP_TASK_SUCCESS_DIFFERENT_COLUMN:
    "hourList/DROP_TASK_SUCCESS_DIFFERENT_COLUMN",
  DROP_TASK_FAILURE: "hourList/DROP_TASK_FAILURE",
  ADD_TASK_BY_COLUMN: "hourList/ADD_TASK_BY_COLUMN",
  ADD_TASK_FOR_WEEK: "hourList/ADD_TASK_FOR_WEEK",
  UPDATE_TASK: "hourList/UPDATE_TASK",
  REMOVE_HOUR_FROM_COLUMN: "hourList/REMOVE_HOUR_FROM_COLUMN",
  GET_HOURS_LIST: "hourList/GET_HOURS_LIST",
  GET_HOURS_LIST_SUCCESS: "hourList/GET_HOURS_LIST_SUCCESS",
  GET_HOURS_LIST_FAILURE: "hourList/GET_HOURS_LIST_FAILURE",
  GET_PROJECTS_FAILURE: "hourList/GET_PROJECTS_FAILURE",
};

const initialState = {
  isLoading: true,
  data: {
    tasks: {},
    columns: {},
    projects: {},
    columnOrder: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  error: "",
};

export const hourList = (state = initialState, action) => {
  switch (action.type) {
    case types.DROP_TASK_SUCCESS_SAME_COLUMN: {
      const { column } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          columns: {
            ...state.data.columns,
            [column.id]: column,
          },
        },
      };
    }
    case types.DROP_TASK_START: {
      return {
        ...state,
      };
    }
    case types.DROP_TASK_SUCCESS_DIFFERENT_COLUMN: {
      const { columns } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          columns,
        },
        isLoading: false,
      };
    }
    case types.ADD_TASK_BY_COLUMN: {
      const { task, columnId } = action.payload;

      task.duration = task.amount;
      return {
        ...state,
        data: {
          ...state.data,
          tasks: {
            ...state.data.tasks,
            ["task-" + task.id]: task,
          },
          columns: {
            ...state.data.columns,
            [columnId]: {
              ...state.data.columns[columnId],
              totalHours: (state.data.columns[columnId].totalHours +=
                parseFloat(getAmountFromFloatDigit(task.amount))),
              taskIds: [
                ...state.data.columns[columnId].taskIds,
                "task-" + task.id,
              ],
            },
          },
        },
      };
    }
    case types.ADD_TASK_FOR_WEEK: {
      const { task, dateStartEndObj } = action.payload;
      const { columns } = state.data;
      const taskForWeek = {};
      var i = 0;
      state.data.columnOrder.map((key) => {
        if (key !== "Saturday" && key !== "Sunday") {
          var id = task.id[i];
          taskForWeek["task-" + id] = {
            amount: task.amount,
            duration: task.amount,
            hour_type_id: task.hour_type_id,
            comment: task.comment,
            start_time: dateStartEndObj.startDate[i]._d,
            end_time: dateStartEndObj.endDate[i]._d,
            task_id: task.task_id,
            id: parseInt(id, 10),
            project_id: parseInt(task.project_id, 10),
          };
          columns[key].totalHours += parseFloat(
            getAmountFromFloatDigit(task.amount)
          );
          columns[key].taskIds.push("task-" + id);
          i = i + 1;
        }
        return taskForWeek;
      });
      const tasks = { ...state.data.tasks, ...taskForWeek };
      return {
        ...state,
        data: {
          ...state.data,
          tasks,
          columns,
        },
      };
    }
    case types.UPDATE_TASK: {
      const { task } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          tasks: { ...state.data.tasks, ["task-" + task.id]: task },
        },
        isLoading: false,
      };
    }
    case types.REMOVE_HOUR_FROM_COLUMN: {
      const { hourId, columnId } = action.payload;
      const columns = { ...state.data.columns };
      const tasks = { ...state.data.tasks };
      var weeDay = capitalize(columnId);
      var taskIndex = columns[weeDay].taskIds.indexOf("task-" + hourId);
      columns[weeDay].taskIds.splice(taskIndex, 1);
      columns[weeDay].totalHours -= tasks["task-" + hourId].amount;
      delete tasks["task-" + hourId];

      return {
        ...state,
        data: {
          ...state.data,
          columns,
          tasks,
        },
      };
    }
    case types.GET_HOURS_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.GET_HOURS_LIST_SUCCESS: {
      const { hourlist, projects } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          columns: hourlist.columns,
          tasks: hourlist.tasks,
          projects,
        },
        isLoading: false,
      };
    }
    case types.GET_PROJECTS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
export const dropTaskSameColumn = (column) => {
  return (dispatch, getState) => {
    dispatch({
      type: types.DROP_TASK_SUCCESS_SAME_COLUMN,
      payload: { column },
    });
  };
};
export const dropTaskDifferentColumn = (column) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DROP_TASK_START,
    });
    const states = getState();
    const newColumn = column;
    const { columns, tasks } = states.hourList.data;
    let newTaskId = [];
    let newWeekday = [];
    Object.keys(columns).map(function (i, key) {
      if (newColumn[i]) {
        if (states.hourList.data.columns[i]) {
          states.hourList.data.columns[i].totalHours = 0;
          newColumn[i].taskIds.map((taskId) => {
            states.hourList.data.columns[i].totalHours += parseFloat(
              getAmountFromFloatDigit(tasks[taskId].amount)
            );
            if (
              states.hourList.data.columns[i].taskIds.indexOf(taskId) === -1
            ) {
              newTaskId = taskId;
              newWeekday = newColumn[i].dayName;
            }
            return newTaskId;
          });

          states.hourList.data.columns[i] = {
            ...states.hourList.data.columns[i],
            taskIds: newColumn[i].taskIds,
          };
        }
      }
      return i;
    });
    var newStartDate = getDateOfISOWeek(newWeekday);
    const id = tasks[newTaskId].id;
    const requestBody = {
      start_time: newStartDate,
      end_time: moment(newStartDate).add(tasks[newTaskId].amount, "hour"),
    };
    await updateHourByDateApi(id, requestBody);
    dispatch({
      type: types.DROP_TASK_SUCCESS_DIFFERENT_COLUMN,
      payload: { columns },
    });
  };
};
export const removeHourFromWeek = (hourId, columnId) => {
  return {
    type: types.REMOVE_HOUR_FROM_COLUMN,
    payload: {
      hourId,
      columnId,
    },
  };
};

const getHoursList = (requestBody) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_HOURS_LIST,
      });
      const projectResponse = await getProjectsApi();
      const response = await hoursListApi(requestBody);

      var projectList = {};
      if (projectResponse.data.entries) {
        projectList = projectResponse.data.entries;
      }
      var hrsList =
        response.data && response.data.entries ? response.data.entries : [];
      const hourlist = {};

      //modifyHoursList(hrsList);
      var tasklist = {};
      hrsList.map((task) => {
        var duration = task.amount.split(".");
        tasklist["task-" + task.id] = {
          hourId: task.id,
          id: task.id,
          project_id: task.project_id,
          task_id: task.task_id,
          task_name: task.task_name,
          hour_type_id: task.hour_type_id,
          hour_type_name: task.hour_type_name,
          comment: task.comment,
          duration: duration[0] + "h " + duration[1] + "m",
          amount: getAmountFromFloatDigit(task.amount),
          start_time: moment(task.start_time).clone(),
          end_time: moment(task.end_time).clone(),
          type: "day",
        };
        return true;
      });
      hourlist.tasks = tasklist;
      var columnsList = {
        Sunday: { id: "Sunday", dayName: "Sunday", totalHours: 0, taskIds: [] },
        Monday: { id: "Monday", dayName: "Monday", totalHours: 0, taskIds: [] },
        Tuesday: {
          id: "Tuesday",
          dayName: "Tuesday",
          totalHours: 0,
          taskIds: [],
        },
        Wednesday: {
          id: "Wednesday",
          dayName: "Wednesday",
          totalHours: 0,
          taskIds: [],
        },
        Thursday: {
          id: "Thursday",
          dayName: "Thursday",
          totalHours: 0,
          taskIds: [],
        },
        Friday: { id: "Friday", dayName: "Friday", totalHours: 0, taskIds: [] },
        Saturday: {
          id: "Saturday",
          dayName: "Saturday",
          totalHours: 0,
          taskIds: [],
        },
      };

      hrsList.map((task) => {
        var weekDay = getWeekday(moment(task.start_time).clone()._d);
        if (weekDay !== undefined) {
          if (columnsList[weekDay]) {
            columnsList[weekDay].id = weekDay;
            columnsList[weekDay].dayName = weekDay;
            columnsList[weekDay].totalHours += parseFloat(
              getAmountFromFloatDigit(task.amount)
            );
            columnsList[weekDay].taskIds.push("task-" + task.id);
          }
        }
        return true;
      });
      hourlist.columns = columnsList;

      dispatch(
        hourListOldAction.getHoursOldList(requestBody, hrsList, projectList)
      );
      dispatch({
        type: types.GET_HOURS_LIST_SUCCESS,
        payload: { hourlist, projects: projectList },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Authentication Failed!");
            break;
          case 403:
            message = i18next.t("User permissions error");
            break;
          case 500:
          default:
            message = i18next.t("Failure on authentication");
            break;
        }
      }
      dispatch({
        type: types.GET_PROJECTS_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};

//const modifyHoursList = data => {};

export const actions = {
  dropTaskSameColumn,
  dropTaskDifferentColumn,
  getHoursList,
};
