import i18next from "i18next";
//import { reactI18nextModule } from "react-i18next";
import LngDetector from "i18next-browser-languagedetector";
import language_en from "../translations/en.json";
import language_no from "../translations/no.json";
import language_de from "../translations/de.json";
import language_ro from "../translations/ro.json";
import language_sv from "../translations/sv.json";
const detectionOptions = {
  order: ["localStorage", "navigator"],
  lookupLocalStorage: "language",
};

//https://react.i18next.com/legacy-v9/step-by-step-guide

i18next
  .use(LngDetector)
  //.use(reactI18nextModule)
  .init({
    resources: {
      en: {
        translation: language_en,
      },
      nb: {
        translation: language_no,
      },
      nn: {
        translation: language_no,
      },
      no: {
        translation: language_no,
      },
      de: {
        translation: language_de,
      },
      ro: {
        translation: language_ro,
      },
      sv: {
        translation: language_sv,
      },
    },
    detection: detectionOptions,
    fallbackLng: "en",
    keySeparator: false,
  });

export default i18next;
