import React, { Component, Suspense } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { APP_ROUTES } from "../Utilities/appConstants";
import { default as store, history } from "../app/store";
import { Provider } from "react-redux";
import { actions as authActions } from "../app/auth";
import { SnackbarProvider } from "notistack";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import moment from "moment";
import getLanguage from "../Utilities/getLanguage";
import { getLocale } from "../Utilities/utils";
import CookiesNotification from "../components/common/CookiesNotification";

import "../App.css";
import { OverlayIndicator } from "../modules/common/components/LoadingIndicators";
//import { ThemeProvider } from "@mui/material/styles";
import ThemeProvider from "../theme";
//import { StyledEngineProvider } from "@mui/material/styles";
import { CollapseDrawerProvider } from "../contexts/CollapseDrawerContext";

import "moment/locale/nb";
import "moment/locale/en-ca";
import "moment/locale/de";
import "moment/locale/ro";
import "moment/locale/sv";

const Landing = React.lazy(() => import("../containers/landing"));
const Main = React.lazy(() => import("../components/layouts/Main"));
const LoginContainer = React.lazy(() =>
  import("../containers/session/LoginContainer")
);
const ForgotPassword = React.lazy(() => import("../containers/forgotPassword"));
const ResetPassword = React.lazy(() => import("../containers/resetPassword"));
const SignUpContainer = React.lazy(() =>
  import("../containers/session/SignUpContainer")
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      language: getLanguage(),
      locale: "en",
    };
  }
  componentDidMount() {
    this.handleToggleAuth();

    this.setState({
      locale: getLocale(this.state.language),
    });
  }
  handleToggleAuth = () => {
    this.setState({
      isAuthenticated: authActions.isLoggedIn() ? true : false,
    });
  };

  render() {
    return (
      <Provider store={store}>
        <CollapseDrawerProvider>
          <ThemeProvider>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Router history={history}>
                <Suspense fallback={<OverlayIndicator isLoading={true} />}>
                  <CookiesNotification />
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    locale={this.state.locale}
                  >
                    <Switch>
                      <Route
                        exact
                        path={APP_ROUTES.login}
                        render={(props) => (
                          <LoginContainer
                            {...props}
                            onToggleAuth={() => this.handleToggleAuth()}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={APP_ROUTES.signUp}
                        render={(props) => (
                          <SignUpContainer
                            {...props}
                            onToggleAuth={() => this.handleToggleAuth()}
                          />
                        )}
                      />
                      <Route
                        exact
                        path={APP_ROUTES.forgotPassword}
                        render={(props) => <ForgotPassword {...props} />}
                      />
                      <Route
                        exact
                        path={APP_ROUTES.resetPassword}
                        render={(props) => <ResetPassword {...props} />}
                      />
                      <Route
                        exact
                        path={APP_ROUTES.confirmed}
                        render={(props) => (
                          <LoginContainer
                            {...props}
                            onToggleAuth={() => this.handleToggleAuth()}
                          />
                        )}
                      />
                      <Route
                        render={(props) =>
                          this.state.isAuthenticated ? (
                            <Main
                              {...props}
                              onToggleAuth={() => this.handleToggleAuth()}
                            />
                          ) : (
                            <Landing
                              {...props}
                              onToggleAuth={() => this.handleToggleAuth()}
                            />
                          )
                        }
                      />
                    </Switch>
                  </LocalizationProvider>
                </Suspense>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </CollapseDrawerProvider>
      </Provider>
    );
  }
}

export default App;
