import createReducer from "../createReducer";
import * as types from "../../actions/types";
import { logout } from "../../Utilities/api.config";

const initialState = {
  isLoading: false,
  successMessage: null,
  errorMessage: null,
  hmskTemplateList: {},
  hmskTemplateData: {},
};

export const hmskTemplateReducer = createReducer(initialState, {
  [types.ADD_HMSK_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.ADD_HMSK_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.ADD_HMSK_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },
  [types.GET_HMSK_TEMPLATES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
    });
  },
  [types.GET_HMSK_TEMPLATES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskTemplateList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_TEMPLATES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskTemplateData: {},
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.GET_HMSK_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskTemplateData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_HMSK_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.UPDATE_HMSK_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.UPDATE_HMSK_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_HMSK_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.DELETE_HMSK_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.DELETE_HMSK_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
});
