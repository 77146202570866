import createReducer from "../createReducer";
import * as types from "../../actions/types";
import { logout } from "../../Utilities/api.config";

const initialState = {
  isLoading: false,
  imageSuccess: null,
  imageError: null,
  successMessage: null,
  errorMessage: null,
  hmskCheckListRecords: {},
  hmskCheckListData: {},
  hmskCheckListFillStatus: null,
};

export const hmskCheckListReducer = createReducer(initialState, {
  [types.UPLOAD_IMAGE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      imageSuccess: null,
      imageError: null,
    });
  },
  [types.UPLOAD_IMAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      imageSuccess: action.payload,
      imageError: null,
    });
  },
  [types.UPLOAD_IMAGE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      imageSuccess: null,
      imageError: action.payload,
    });
  },
  [types.DELETE_IMAGE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      imageSuccess: null,
      imageError: null,
    });
  },
  [types.DELETE_IMAGE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      imageSuccess: action.payload,
      imageError: null,
    });
  },
  [types.DELETE_IMAGE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      imageSuccess: null,
      imageError: action.payload,
    });
  },
  [types.ADD_HMSK_CHECK_LIST](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.ADD_HMSK_CHECK_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      successMessage: action.payload,
      errorMessage: null,
    });
  },
  [types.ADD_HMSK_CHECK_LIST_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      isLoading: false,
      successMessage: null,
      errorMessage: action.payload,
    });
  },
  [types.GET_HMSK_CHECK_LISTS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
    });
  },
  [types.GET_HMSK_CHECK_LISTS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskCheckListRecords: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_CHECK_LISTS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_CHECK_LIST](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.GET_HMSK_CHECK_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskCheckListData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_CHECK_LIST_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.UPDATE_HMSK_CHECK_LIST](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.UPDATE_HMSK_CHECK_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.UPDATE_HMSK_CHECK_LIST_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_HMSK_CHECK_LIST](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
    });
  },
  [types.DELETE_HMSK_CHECK_LIST_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      errorMessage: null,
      isLoading: false,
    });
  },
  [types.DELETE_HMSK_CHECK_LIST_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      successMessage: null,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskCheckListFillStatus: null,
      isLoading: true,
    });
  },
  [types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      hmskCheckListFillStatus: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HMSK_CHECK_LISTS_NEED_TO_FILL_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
});
