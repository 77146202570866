//@flow

import { normalize, normalizeForReactSelect } from "../../Utilities/utils";
import {
  getTasksByProjectApi,
  createTaskByProjectApi,
} from "../../services/hours";
import { getErrorCode, logout } from "../../Utilities/api.config";
import i18next from "i18next";
const initialState = {
  isLoading: false,
  data: {},
  ids: [],
  selected: null,
  error: null,
};
const types = {
  GET_TASKS_BY_PROJECT: "GET_TASKS_BY_PROJECT",
  GET_TASKS_BY_PROJECT_SUCCESS: "GET_TASKS_BY_PROJECT_SUCCESS",
  GET_TASKS_BY_PROJECT_FAILURE: "GET_TASKS_BY_PROJECT_FAILURE",
  CREATE_TASK_BY_PROJECT: "CREATE_TASK_BY_PROJECT",
  CREATE_TASK_BY_PROJECT_SUCCESS: "CREATE_TASK_BY_PROJECT_SUCCESS",
  CREATE_TASK_BY_PROJECT_FAILURE: "CREATE_TASK_BY_PROJECT_FAILURE",
  SELECT_TASK: "hours/SELECT_TASK",
};
export const tasksByProject = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TASKS_BY_PROJECT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_TASKS_BY_PROJECT_SUCCESS: {
      const { data, ids } = action.payload;
      return {
        ...state,
        data,
        ids,
        isLoading: false,
      };
    }
    case types.GET_TASKS_BY_PROJECT_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    case types.SELECT_TASK: {
      const { selected } = action.payload;
      return {
        ...state,
        selected,
      };
    }
    case types.CREATE_TASK_BY_PROJECT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_TASK_BY_PROJECT_SUCCESS: {
      const { task } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [task.id]: task,
        },
        ids: state.ids.concat(task.id),
      };
    }
    default:
      return state;
  }
};
export const getTasksByProject = (
  inputValue,
  reactSelectCallback,
  projectId
) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_TASKS_BY_PROJECT,
      });
      const response = await getTasksByProjectApi({ project_id: projectId });
      const normalizedTaskTypes = normalize({
        data: response.data.entries,
        key: "id",
      });
      dispatch({
        type: types.GET_TASKS_BY_PROJECT_SUCCESS,
        payload: normalizedTaskTypes,
      });
      const reactSelectData = normalizeForReactSelect({
        data: response.data.entries,
      });
      reactSelectCallback(reactSelectData);
    } catch (error) {
      dispatch({
        type: types.GET_TASKS_BY_PROJECT_FAILURE,
        payload: { error },
      });
    }
  };
};
export const createTaskByProject = (requestBody) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.CREATE_TASK_BY_PROJECT,
      });
      const projectId = getState().projects.selected;
      const response = await createTaskByProjectApi(requestBody);
      const id = response.data.id;
      dispatch({
        type: types.CREATE_TASK_BY_PROJECT_SUCCESS,
        payload: {
          task: { name: requestBody.name, projectId, id: id.toString() },
        },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }
      dispatch({
        type: types.CREATE_TASK_BY_PROJECT_FAILURE,
        payload: { error: message },
      });
    }
  };
};

const selectTask = (selected) => {
  return {
    type: types.SELECT_TASK,
    payload: { selected },
  };
};
export const actions = {
  getTasksByProject,
  createTaskByProject,
  selectTask,
};
