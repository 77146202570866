import { post } from "../Utilities/api.config.js";
import { AUTHORIZED_CLIENT_TOKEN } from "../Utilities/appConstants";
export const postSignInAuthorizeApi = (params) => {
  const request = {
    subUrl: `/oauth/authorize`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    data: params,
  };
  return post(request);
};
export const postSignInTokenApi = (params) => {
  const request = {
    subUrl: `/oauth/token`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    data: params,
  };
  return post(request);
};
/*export const getSignInClientApi = params => {
  const request = {
    subUrl: `/clients`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN
    },
    data: params
  };
  return get(request); 
};*/
