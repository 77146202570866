import {
  postSignInAuthorizeApi,
  postSignInTokenApi,
  //getSignInClientApi
} from "../services/signIn";
import { getErrorCode } from "../Utilities/api.config";
import i18next from "i18next";

var crypto = require("crypto-browserify");

const intialState = {
  isLoading: false,
  data: {},
  error: null,
  isUserLoggedIn: false,
};

const types = {
  POST_LOGIN: "signIn/POST_LOGIN",
  POST_LOGIN_SUCCESS: "signIn/POST_LOGIN_SUCCESS",
  POST_LOGIN_FAILURE: "signIn/POST_LOGIN_FAILURE",
  POST_LOGOUT_SUCCESS: "signIn/POST_LOGOUT_SUCCESS",
};

export const signIn = (state = intialState, action) => {
  switch (action.type) {
    case types.POST_LOGIN:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.POST_LOGIN_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        data,
        isLoading: false,
        isUserLoggedIn: true,
      };
    }
    case types.POST_LOGIN_FAILURE: {
      const { error } = action.payload;
      return {
        ...intialState,
        error,
        isLoading: false,
      };
    }
    case types.POST_LOGOUT_SUCCESS: {
      return {
        ...intialState,
        isUserLoggedIn: false,
      };
    }
    default:
      return state;
  }
};

function base64URLEncode(str) {
  return str
    .toString("base64")
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
}

function sha256(buffer) {
  return crypto.createHash("sha256").update(buffer).digest();
}

/* not required due to .env variables
const getClientDeviceData = () => {
  return async (dispatch, getState) => {
    const response = await getSignInClientApi();
    //console.log(response);
    if (response && response.data && response.data.entries) {
      let webEntry = response.data.entries.find(entry => {
        return entry.name === "Web";
      });

      //console.log(webEntry);
      if (webEntry.uid) {
        localStorage.setItem("client_id", webEntry.uid);
        localStorage.setItem("client_secret", webEntry.secret);
        localStorage.setItem("redirect_uri", webEntry.redirect_uri);
      }
    }
  };
};*/

const postLogin = (loginData, isAzure) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: types.POST_LOGIN });
      const verifier = base64URLEncode(crypto.randomBytes(32));
      const challenge = base64URLEncode(sha256(verifier));

      let postAuthorizeObj = {
        email: loginData.email,
        password: loginData.password,
        response_type: "code",
        client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
        scope: "read write",
        code_challenge: challenge,
        code_challenge_method: "S256",
      };

      if (isAzure) {
        postAuthorizeObj["email"] = loginData.email;
        postAuthorizeObj["microsoft_id"] = loginData.microsoft_id;
        postAuthorizeObj["microsoft_token"] = loginData.microsoft_token;
      } else {
        postAuthorizeObj["email"] = loginData.email;
        postAuthorizeObj["password"] = loginData.password;
      }

      // call authorize api and get oAuth Authorization Code in response
      const authResponse = await postSignInAuthorizeApi(postAuthorizeObj);

      if (authResponse.data && authResponse.data.code) {
        const postTokenObj = {
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
          client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
          redirect_uri: process.env.REACT_APP_OAUTH_REDIRECT_URI,
          code_verifier: verifier,
          grant_type: "authorization_code",
          code: authResponse.data.code,
        };

        // call token api and get Access Token in response
        const tokenResponse = await postSignInTokenApi(postTokenObj);

        dispatch({
          type: types.POST_LOGIN_SUCCESS,
          payload: {
            data: tokenResponse.data,
          },
        });
      }
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        switch (errorCode) {
          case 500:
            message = i18next.t("Failure on authentication");
            break;
          case 401:
            message =
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message
                ? error.response.data.message
                : i18next.t(
                    "Incorrect email password combination. Please check and try again"
                  );
            break;
          case 404:
            message = i18next.t(
              "Incorrect email password combination. Please check and try again"
            );
            break;
          default:
            message = i18next.t("Failure on authentication");
            break;
        }
      }
      dispatch({
        type: types.POST_LOGIN_FAILURE,
        payload: { error: message },
      });
    }
  };
};

export const actions = {
  postLogin,
  //getClientDeviceData
};
