import { hoursListApi } from "../../services/hours";
import { deleteHourApi } from "../../services/hours";
import { removeHourFromWeek } from "../../reducers/hours/hourList";
import { getProjectsApi } from "../../services/projects";
import { getErrorCode, logout } from "../../Utilities/api.config";
import moment from "moment";
import { getWeekday, getAmountFromFloatDigit } from "../../Utilities/utils";
import i18next from "i18next";
export const types = {
  UPDATE_TASK: "hourListOld/UPDATE_TASK",
  REMOVE_HOUR_FROM_ROW: "hourListOld/REMOVE_HOUR_FROM_ROW",
  REMOVE_HOUR_FROM_ROW_SUCCESS: "hourListOld/REMOVE_HOUR_FROM_ROW_SUCCESS",
  REMOVE_HOUR_FROM_ROW_FAILURE: "hourListOld/REMOVE_HOUR_FROM_ROW_FAILURE",
  GET_HOURS_TABLE_LIST: "hourListOld/GET_HOURS_TABLE_LIST",
  GET_HOURS_TABLE_LIST_SUCCESS: "hourListOld/GET_HOURS_TABLE_LIST_SUCCESS",
  GET_HOURS_TABLE_LIST_FAILURE: "hourListOld/GET_HOURS_TABLE_LIST_FAILURE",
  GET_PROJECTS_FAILURE: "hourListOld/GET_PROJECTS_FAILURE",
  START_EDIT_HOUR: "hourListOld/START_EDIT_HOUR",
  SAVE_HOUR: "hourListOld/SAVE_HOUR_HOUR",
};

const initialState = {
  isLoading: true,
  data: {
    tasks: {},
    columns: {},
    projects: {},
    columnOrder: [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
  },
  error: "",
};

export const hourListOld = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_TASK: {
      const { task } = action.payload;
      task.flag = "view";
      return {
        ...state,
        data: {
          ...state.data,
          tasks: { ...state.data.tasks, ["task-" + task.id]: task },
        },
        isLoading: false,
      };
    }
    case types.REMOVE_HOUR_FROM_COLUMN: {
      const { hourId } = action.payload;
      const tasks = { ...state.data.tasks };
      delete tasks["task-" + hourId];
      return {
        ...state,
        data: {
          ...state.data,
          tasks,
        },
      };
    }
    case types.REMOVE_HOUR_FROM_ROW: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.REMOVE_HOUR_FROM_ROW_SUCCESS: {
      const { hourId } = action.payload;
      const columns = { ...state.data.columns };
      const tasks = { ...state.data.tasks };
      delete tasks["task-" + hourId];
      return {
        ...state,
        data: {
          ...state.data,
          columns,
          tasks,
        },
        isLoading: false,
      };
    }
    case types.REMOVE_HOUR_FROM_ROW_FAILURE: {
      return {
        ...state,
      };
    }
    case types.GET_HOURS_TABLE_LIST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.GET_HOURS_TABLE_LIST_SUCCESS: {
      const { hourlist, projects } = action.payload;
      return {
        ...state,
        data: {
          ...state.data,
          columns: hourlist.columns,
          tasks: hourlist.tasks,
          projects,
        },
        isLoading: false,
      };
    }
    case types.GET_PROJECTS_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        error,
        isLoading: false,
      };
    }
    case types.START_EDIT_HOUR: {
      const { id } = action.payload;
      const tasksObj = { ...state.data.tasks };
      Object.keys(tasksObj).map((key) => {
        if (tasksObj[key].id === id) {
          tasksObj[key].flag = null;
        }
        return tasksObj[key].flag;
      });
      return {
        ...state,
        data: {
          ...state.data,
        },
      };
    }
    case types.SAVE_HOUR: {
      const { id } = action.payload;
      const tasksObj = { ...state.data.tasks };
      Object.keys(tasksObj).map((key) => {
        if (tasksObj[key].id === id) {
          tasksObj[key].flag = "view";
        }
        return tasksObj[key].flag;
      });
      return {
        ...state,
        data: {
          ...state.data,
        },
      };
    }
    default:
      return state;
  }
};

const getHoursOldList = (parm, data, project) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_HOURS_TABLE_LIST,
      });
      var projectList;
      var hrsList;
      if (!data || !project) {
        const projectResponse = await getProjectsApi();
        const response = await hoursListApi(parm);
        if (projectResponse.data.entries) {
          projectList = projectResponse.data.entries;
        }
        hrsList =
          response.data && response.data.entries ? response.data.entries : [];
      } else {
        projectList = project;
        hrsList = data;
      }
      const hourlist = {};
      modifyHoursList(hrsList);
      var taskTable = {};
      hrsList.map((task) => {
        taskTable["task-" + task.task_id] = {
          id: task.task_id,
          hours_id: [],
          project_id: task.project_id,
          task_id: task.task_id,
          task_name: task.task_name,
          hour_type_id: task.hour_type_id,
          hour_type_name: task.hour_type_name,
          monday: 0,
          tuesday: 0,
          wednesday: 0,
          thursday: 0,
          friday: 0,
          saturday: 0,
          sunday: 0,
          open: false,
          flag: "view",
          type: "day",
        };
        hrsList.map((taskList) => {
          if (task.task_id === taskList.task_id) {
            var weekDay = getWeekday(moment(taskList.start_time).clone());
            if (weekDay !== undefined) {
              taskTable["task-" + task.task_id].hours_id.push({
                [weekDay.toLowerCase()]: taskList.id,
              });
              taskTable["task-" + task.task_id][weekDay.toLowerCase()] =
                parseFloat(getAmountFromFloatDigit(taskList.amount));
            }
          }
          return true;
        });
        return taskTable["task-" + task.task_id];
      });
      hourlist.tasks = taskTable;
      var columnsList = {
        Sunday: { id: "Sunday", dayName: "Sunday", totalHours: 0, taskIds: [] },
        Monday: { id: "Monday", dayName: "Monday", totalHours: 0, taskIds: [] },
        Tuesday: {
          id: "Tuesday",
          dayName: "Tuesday",
          totalHours: 0,
          taskIds: [],
        },
        Wednesday: {
          id: "Wednesday",
          dayName: "Wednesday",
          totalHours: 0,
          taskIds: [],
        },
        Thursday: {
          id: "Thursday",
          dayName: "Thursday",
          totalHours: 0,
          taskIds: [],
        },
        Friday: { id: "Friday", dayName: "Friday", totalHours: 0, taskIds: [] },
        Saturday: {
          id: "Saturday",
          dayName: "Saturday",
          totalHours: 0,
          taskIds: [],
        },
      };

      hrsList.map((task) => {
        var weekDay = getWeekday(moment(task.start_time).clone());
        if (weekDay !== undefined) {
          if (columnsList[weekDay]) {
            columnsList[weekDay].id = weekDay;
            columnsList[weekDay].dayName = weekDay;
            columnsList[weekDay].totalHours += parseFloat(
              getAmountFromFloatDigit(task.amount)
            );
            columnsList[weekDay].taskIds.push("task-" + task.id);
          }
        }
        return columnsList[weekDay];
      });
      hourlist.columns = columnsList;
      dispatch({
        type: types.GET_HOURS_TABLE_LIST_SUCCESS,
        payload: { hourlist, projects: projectList },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Authentication Failed!");
            break;
          case 403:
            message = i18next.t("User permissions error");
            break;
          case 500:
          default:
            message = i18next.t("Failure on authentication");
            break;
        }
      }
      dispatch({
        type: types.GET_PROJECTS_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
const deleteHour = (requestData) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.REMOVE_HOUR_FROM_ROW,
      });
      let count = 0;
      let delteId = [];
      let columnId = [];
      requestData.ids.map((id) => {
        Object.keys(id).map((key) => {
          columnId.push(key);
          delteId.push(id[key]);
          return delteId;
        });
        return true;
      });
      var runQuery = true;
      var apiInterval = setInterval(async () => {
        if (runQuery) {
          runQuery = false;
          await deleteHourApi(delteId[count]).then((reslove) => {
            dispatch(removeHourFromWeek(delteId[count], columnId[count]));
            runQuery = true;
            count++;
          });
        }
        if (delteId.length === count) {
          clearInterval(apiInterval);
          dispatch({
            type: types.REMOVE_HOUR_FROM_ROW_SUCCESS,
            payload: { hourId: requestData.id },
          });
        }
      }, 200);
    } catch (error) {
      dispatch({
        type: types.REMOVE_HOUR_FROM_ROW_FAILURE,
        payload: { error },
      });
    }
  };
};
const modifyHoursList = (data) => {};
const editHour = (id) => {
  return {
    type: types.START_EDIT_HOUR,
    payload: { id },
  };
};
const saveHour = (id) => {
  return {
    type: types.SAVE_HOUR,
    payload: { id },
  };
};
const checkTaskExist = (data) => {
  return (dispatch, getState) => {
    const state = getState();

    function filterObj(keys, obj) {
      const newObj = {};
      Object.keys(obj.tasks).map((key) => {
        if (obj.tasks[key].id === parseInt(data, 10)) {
          newObj.value = obj.tasks[key].task_name;
          newObj.id = obj.tasks[key].task_id;
        }
        return newObj;
      });
      return newObj;
    }
    const resp = filterObj("task_name", state.hourListOld.data);
    return resp.value ? true : false;
  };
};
export const actions = {
  getHoursOldList,
  deleteHour,
  editHour,
  saveHour,
  checkTaskExist,
};
