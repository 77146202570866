import createReducer from "../createReducer";
import * as types from "../../actions/types";
import { logout } from "../../Utilities/api.config";

const initialState = {
  isLoading: false,
  templates: [],
  reportTableData: [],
  reportTableDataLoading: false,
  addonReportTableData: [],
  addonReportTableDataLoading: false,
  planningReportTableData: [],
  planningReportTableDataLoading: false,
  employeeReportData: [],
  employeeReportDataLoading: false,
  salaryExportHistoryData: [],
  revertSalaryAction: null,
  templateData: {},
  successMessage: null,
  errorMessage: null,
  newTemplateId: null,
  error: null,
};

export const reportReducer = createReducer(initialState, {
  [types.GET_TEMPLATES](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_TEMPLATES_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      templates: action.payload,
      isLoading: false,
    });
  },
  [types.GET_TEMPLATES_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
      error: null,
    });
  },
  [types.ADD_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      newTemplateId: action.payload.id,
      isLoading: false,
    });
  },
  [types.ADD_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      newTemplateId: null,
      isLoading: false,
    });
  },
  [types.GET_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      templateData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
      error: null,
    });
  },
  [types.PUT_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_TEMPLATE](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      successMessage: null,
      errorMessage: null,
      error: null,
    });
  },
  [types.DELETE_TEMPLATE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      successMessage: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_TEMPLATE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      isLoading: false,
    });
  },
  [types.GET_REPORT_TABLE](state, action) {
    return Object.assign({}, state, {
      ...state,
      reportTableData: [],
      reportTableDataLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_REPORT_TABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      reportTableData: action.payload,
      reportTableDataLoading: false,
    });
  },
  [types.GET_REPORT_TABLE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      reportTableDataLoading: false,
    });
  },
  [types.GET_ADDON_REPORT_TABLE](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonReportTableData: [],
      addonReportTableDataLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_ADDON_REPORT_TABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      addonReportTableData: action.payload,
      addonReportTableDataLoading: false,
    });
  },
  [types.GET_ADDON_REPORT_TABLE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      addonReportTableDataLoading: false,
    });
  },
  [types.GET_PLANNING_REPORT_TABLE](state, action) {
    return Object.assign({}, state, {
      ...state,
      planningReportTableData: [],
      planningReportTableDataLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_PLANNING_REPORT_TABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      planningReportTableData: action.payload,
      planningReportTableDataLoading: false,
    });
  },
  [types.GET_PLANNING_REPORT_TABLE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      errorMessage: action.payload,
      planningReportTableDataLoading: false,
    });
  },
  [types.GET_SALARY_EXPORT_HISTORY](state, action) {
    return Object.assign({}, state, {
      salaryExportHistoryData: [],
      isLoading: true,
      error: null,
    });
  },
  [types.GET_SALARY_EXPORT_HISTORY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      salaryExportHistoryData: action.payload,
      isLoading: false,
    });
  },
  [types.GET_SALARY_EXPORT_HISTORY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_REVERT_SALARY_REPORT](state, action) {
    return Object.assign({}, state, {
      revertSalaryAction: null,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_REVERT_SALARY_REPORT_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      revertSalaryAction: action.payload,
      isLoading: false,
    });
  },
  [types.GET_REVERT_SALARY_REPORT_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_EMPLOYEE_REPORT_TABLE](state, action) {
    return Object.assign({}, state, {
      employeeReportData: [],
      employeeReportDataLoading: true,
      errorMessage: null,
      error: null,
    });
  },
  [types.GET_EMPLOYEE_REPORT_TABLE_SUCCESS](state, action) {
    return Object.assign({}, state, {
      employeeReportData: action.payload,
      employeeReportDataLoading: false,
    });
  },
  [types.GET_EMPLOYEE_REPORT_TABLE_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      errorMessage: action.payload,
      employeeReportDataLoading: false,
    });
  },
});
