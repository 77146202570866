import React from "react";
import ReactDOM from "react-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { BUGSNAG_CLIENT } from "./Utilities/appConstants";
import "./app/i18n";

import App from "./app/App";

if (process.env.NODE_ENV === "production") {
  Bugsnag.start({
    apiKey: BUGSNAG_CLIENT,
    plugins: [new BugsnagPluginReact()],
  });

  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  ReactDOM.render(
    <ErrorBoundary>
      <App />
    </ErrorBoundary>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(<App />, document.getElementById("root"));
}

if (module.hot) {
  module.hot.accept("./app/App", () => {
    const App = require("./app/App").default;
    ReactDOM.render(<App />, document.getElementById("root"));
  });
}
