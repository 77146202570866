import createReducer from "./createReducer";
import * as types from "../actions/types";
import { logout } from "../Utilities/api.config";

const initialState = {
  isLoading: false,
  data: [],
  holidayList: {},
  companyHolidayList: {},
  newHoliday: {},
  error: null,
  succMsg: null,
};

export const holidayReducer = createReducer(initialState, {
  [types.GET_HOLIDAYS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      newHoliday: null,
      succMsg: null,
      error: null,
    });
  },
  [types.GET_HOLIDAYS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      holidayList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_HOLIDAYS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_HOLIDAYS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      companyHolidayList: null,
      succMsg: null,
      error: null,
    });
  },
  [types.GET_COMPANY_HOLIDAYS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      companyHolidayList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_COMPANY_HOLIDAYS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_COMPANY_HOLIDAYS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      error: null,
    });
  },
  [types.ADD_COMPANY_HOLIDAYS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      newHoliday: action.payload,
      isLoading: false,
    });
  },
  [types.ADD_COMPANY_HOLIDAYS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_COMPANY_HOLIDAY](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      newHoliday: null,
      succMsg: null,
      error: null,
    });
  },
  [types.DELETE_COMPANY_HOLIDAY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      error: null,
    });
  },
  [types.DELETE_COMPANY_HOLIDAY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_COMPANY_HOLIDAY](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      newHoliday: null,
      error: null,
      succMsg: null,
    });
  },
  [types.PUT_COMPANY_HOLIDAY_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      error: null,
    });
  },
  [types.PUT_COMPANY_HOLIDAY_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      error: action.payload,
      isLoading: false,
    });
  },
});
