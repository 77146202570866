import {
  getProjectsApi,
  createProjectApi,
  getProjectByIdApi,
  getCustomerApi,
} from "../../services/hours";
import { normalize } from "../../Utilities/utils";
import { getErrorCode, logout } from "../../Utilities/api.config";
import i18next from "i18next";
const initialState = {
  isLoading: false,
  data: {},
  ids: [],
  selected: null,
  error: null,
};
export const types = {
  GET_PROJECTS: "GET_PROJECTS",
  GET_PROJECTS_SUCCESS: "GET_PROJECTS_SUCCESS",
  GET_PROJECTS_FAILURE: "GET_PROJECTS_FAILURE",
  SELECT_PROJECT: "hours/SELECT_PROJECT",
  CREATE_PROJECT: "hours/CREATE_PROJECT",
  CREATE_PROJECT_SUCCESS: "hours/CREATE_PROJECT_SUCCESS",
};
export const projects = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROJECTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_PROJECTS_SUCCESS: {
      const { data, ids } = action.payload;
      return {
        ...state,
        data,
        ids,
        isLoading: false,
      };
    }
    case types.GET_PROJECTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    case types.SELECT_PROJECT:
      return {
        ...state,
        selected: action.payload.selected,
      };
    case types.CREATE_PROJECT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.CREATE_PROJECT_SUCCESS: {
      const { projects } = action.payload;
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          [projects.id]: projects,
        },
        ids: state.ids.concat(projects.id),
      };
    }
    default:
      return state;
  }
};
export const getProjects = (search, reactSelectCallback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_PROJECTS,
      });
      const response = await getProjectsApi(search);
      const normalizedProjects = normalize({
        data: response.data.entries,
        key: "id",
      });
      dispatch({
        type: types.GET_PROJECTS_SUCCESS,
        payload: normalizedProjects,
      });
    } catch (error) {
      dispatch({
        type: types.GET_PROJECTS_FAILURE,
        payload: { error },
      });
    }
  };
};
export const getProjectById = (id, reactSelectCallback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_PROJECTS,
      });
      const response = await getProjectByIdApi(id);
      const selected = {
        value: response.data.id,
        label: response.data.name,
      };
      dispatch({
        type: types.SELECT_PROJECT,
        payload: { selected },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.GET_PROJECTS_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
export const createProject = (requestBody) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.CREATE_PROJECT,
      });
      const response = await createProjectApi(requestBody);
      const id = response.data.id;
      dispatch({
        type: types.CREATE_PROJECT_SUCCESS,
        payload: {
          projects: {
            name: requestBody.name,
            id: id.toString(),
          },
        },
      });
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }

      dispatch({
        type: types.CREATE_PROJECT_FAILURE,
        payload: {
          error: message,
        },
      });
    }
  };
};
const getCutomers = async (requestBody, dispatch) => {
  try {
    const response = await getCustomerApi(requestBody);
    return response;
  } catch (error) {
    let errorCode = getErrorCode(error);
    let message = i18next.t("Failure on authentication");
    if (errorCode) {
      if (errorCode === 401) {
        logout();
      }
      switch (errorCode) {
        case 401:
          message = i18next.t("Client is not logged in");
          break;
        case 403:
          message = i18next.t(
            "User without privileges to perform this operation"
          );
          break;
        case 500:
        default:
          message =
            "Bad Request. Something is wrong with the parameters or the request itself.";
          break;
      }
    }

    dispatch({
      type: types.GET_CUSTOMERS_FAILURE,
      payload: {
        error: message,
      },
    });
  }
};
const selectProject = (selected) => {
  return {
    type: types.SELECT_PROJECT,
    payload: { selected },
  };
};
export const actions = {
  getProjects,
  selectProject,
  getProjectById,
  getCutomers,
};
