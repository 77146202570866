import { get, post, deletee } from "../Utilities/api.config.js";
import { AUTHORIZED_CLIENT_TOKEN } from "../Utilities/appConstants";

export const getOrganizationApi = (organizationNumber) => {
  //https://data.brreg.no/enhetsregisteret/api/docs/index.html
  const request = {
    url: `https://data.brreg.no/enhetsregisteret/api/enheter/${organizationNumber}`,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  return get(request);
};

export const checkUserApi = (params) => {
  const request = {
    subUrl: `/user/exists`,
    headers: {
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    params: params,
  };
  return get(request);
};

export const checkCompanyApi = (params) => {
  const request = {
    subUrl: `/company/exists`,
    headers: {
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    params: params,
  };
  return get(request);
};

export const postRegisterApi = (requestBody) => {
  const request = {
    subUrl: `/register`,
    headers: {
      "Content-Type": "application/json",
      Accept: "*",
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    data: requestBody,
  };
  return post(request);
};

export const getAllPlans = (params) => {
  const request = {
    subUrl: `/plans`,
    headers: {
      Authorization: "Bearer " + AUTHORIZED_CLIENT_TOKEN,
    },
    params: params,
  };
  return get(request);
};

export const deleteCompanyApi = (token) => {
  const request = {
    subUrl: `/company`,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return deletee(request);
};
