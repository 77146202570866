import { get } from "../Utilities/api.config.js";
import { AUTHORIZED_CLIENT_TOKEN } from "../Utilities/appConstants";

export const getPlansApi = () => {
  const request = {
    subUrl: "/company/plans",
    headers: {
      Authorization: `Bearer ${AUTHORIZED_CLIENT_TOKEN}`,
    },
  };
  return get(request);
};
