//@flow
import { normalize, normalizeForReactSelect } from "../../Utilities/utils";
import {
  getTaskTypesByTaskApi,
  getTaskTypesByIdApi,
} from "../../services/hours";
import { getErrorCode, logout } from "../../Utilities/api.config";
import i18next from "i18next";
const initialState = {
  isLoading: false,
  data: {},
  ids: [],
  error: null,
};
const types = {
  GET_TASK_TYPE_BY_TASK: "GET_TASK_TYPE_BY_TASK",
  GET_TASK_TYPE_BY_TASK_SUCCESS: "GET_TASK_TYPE_BY_TASK_SUCCESS",
  GET_TASK_TYPE_BY_TASK_FAILURE: "GET_TASK_TYPE_BY_TASK_FAILURE",
  GET_TASK_TYPE_BY_ID: "GET_TASK_TYPE_BY_ID",
  GET_TASK_TYPE_BY_ID_SUCCESS: "GET_TASK_TYPE_BY_ID_SUCCESS",
  GET_TASK_TYPE_BY_ID_FAILURE: "GET_TASK_TYPE_BY_ID_FAILURE",
  CREATE_TASKS_BY_PROJECT: "CREATE_TASKS_BY_PROJECT",
  CREATE_TASKS_BY_PROJECT_SUCCESS: "CREATE_TASKS_BY_PROJECT_SUCCESS",
  CREATE_TASKS_BY_PROJECT_FAILURE: "CREATE_TASKS_BY_PROJECT_FAILURE",
};

export const taskTypesByTask = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TASK_TYPE_BY_TASK:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_TASK_TYPE_BY_TASK_SUCCESS: {
      const { data, ids } = action.payload;
      return {
        ...state,
        data,
        ids,
        isLoading: false,
      };
    }
    case types.GET_TASK_TYPE_BY_TASK_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    case types.GET_TASK_TYPE_BY_ID:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case types.GET_TASK_TYPE_BY_ID_SUCCESS: {
      const { data, ids } = action.payload;
      return {
        ...state,
        data,
        ids,
        isLoading: false,
      };
    }
    case types.GET_TASK_TYPE_BY_ID_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
const getTaskTypesByTask = (inputValue) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_TASK_TYPE_BY_TASK,
      });
      const response = await getTaskTypesByTaskApi({ name: inputValue });
      const normalizedTaskTypes = normalize({
        data: response.data.entries,
        key: "id",
      });
      dispatch({
        type: types.GET_TASK_TYPE_BY_TASK_SUCCESS,
        payload: normalizedTaskTypes,
      });
      // const reactSelectData = normalizeForReactSelect({
      //   data: response.data.entries
      // });
      // reactSelectCallback(reactSelectData);
    } catch (error) {
      let errorCode = getErrorCode(error);
      let message = i18next.t("Failure on authentication");
      if (errorCode) {
        if (errorCode === 401) {
          logout();
        }
        switch (errorCode) {
          case 401:
            message = i18next.t("Client is not logged in");
            break;
          case 403:
            message = i18next.t(
              "User without privileges to perform this operation"
            );
            break;
          case 500:
          default:
            message =
              "Bad Request. Something is wrong with the parameters or the request itself.";
            break;
        }
      }
      dispatch({
        type: types.GET_TASK_TYPE_BY_TASK_FAILURE,
        payload: { error: message },
      });
    }
  };
};
const getTaskTypeById = (inputValue, reactSelectCallback) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.GET_TASK_TYPE_BY_ID,
      });
      const response = await getTaskTypesByIdApi(inputValue);
      const normalizedTaskTypes = normalize({
        data: response.data.entries,
        key: "id",
      });
      dispatch({
        type: types.GET_TASK_TYPE_BY_ID_SUCCESS,
        payload: normalizedTaskTypes,
      });
      const reactSelectData = normalizeForReactSelect({
        data: response.data.entries,
      });
      reactSelectCallback(reactSelectData);
    } catch (error) {
      dispatch({
        type: types.GET_TASK_TYPE_BY_ID_FAILURE,
        payload: {
          error,
        },
      });
    }
  };
};
export const actions = {
  getTaskTypesByTask,
  getTaskTypeById,
};
