import createReducer from "./createReducer";
import * as types from "../actions/types";
import { logout } from "../Utilities/api.config";

const initialState = {
  isLoading: false,
  data: {},
  userGroupsList: {},
  error: null,
  succMsg: null,
  errMsg: null,
};

export const userGroupsReducer = createReducer(initialState, {
  [types.GET_USER_GROUPS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
    });
  },
  [types.GET_USER_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      userGroupsList: action.payload,
      isLoading: false,
    });
  },
  [types.GET_USER_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      error: action.payload,
      isLoading: false,
    });
  },
  [types.DELETE_USER_GROUPS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      succMsg: null,
      errMsg: null,
      error: null,
    });
  },
  [types.DELETE_USER_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.DELETE_USER_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.CREATE_USER_GROUPS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.CREATE_USER_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.CREATE_USER_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
  [types.PUT_USER_GROUPS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: true,
      error: null,
      succMsg: null,
      errMsg: null,
    });
  },
  [types.PUT_USER_GROUPS_SUCCESS](state, action) {
    return Object.assign({}, state, {
      ...state,
      isLoading: false,
      succMsg: action.payload,
      errMsg: null,
    });
  },
  [types.PUT_USER_GROUPS_FAILURE](state, action) {
    if (action.payload.status === 401) {
      logout();
    }
    return Object.assign({}, state, {
      ...initialState,
      succMsg: null,
      errMsg: action.payload,
      isLoading: false,
    });
  },
});
