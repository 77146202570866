const ACCESS_TOKEN_KEY = "ods_access_token";
const ACCESS_TOKEN_TYPE = "ods_access_type";
const TOKEN_EXPIRY_KEY = "ods_token_expires_in";
const AZURE_ACCOUNT_ID = "ods_azure_account_id";

const saveAccessToken = (accessToken) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

/*
 * Persist auth cred
 * */
const saveSession = (authResult) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, authResult.access_token);
  localStorage.setItem(ACCESS_TOKEN_TYPE, authResult.token_type);
  const expiresAt = JSON.stringify(
    authResult.expires_in * 1000 + new Date().getTime()
  );
  localStorage.setItem(TOKEN_EXPIRY_KEY, expiresAt);
};

/*
 * Clear auth cred
 * */
const clearSession = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ACCESS_TOKEN_TYPE);
  localStorage.removeItem(TOKEN_EXPIRY_KEY);
  localStorage.removeItem(AZURE_ACCOUNT_ID);
};

/*
 * IsTokenExpired
 * */
const isTokenExpired = () => {
  const expiresAt = getExpiresAt();
  const expired = new Date().getTime() > expiresAt;
  if (expired) {
    clearSession();
  }
  return expired;
};

/*
 * IsLoggedIn
 * */
const isLoggedIn = () => {
  const accessToken = getAccessToken();
  let result = !!accessToken && !isTokenExpired();
  return result;
};

const getExpiresAt = () => {
  return JSON.parse(localStorage.getItem(TOKEN_EXPIRY_KEY));
};

const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY)
    ? localStorage.getItem(ACCESS_TOKEN_KEY)
    : "";
};

const setAzureAccountId = (accountId) => {
  localStorage.setItem(AZURE_ACCOUNT_ID, accountId);
};

const getAzureAccountId = () => {
  return localStorage.getItem(AZURE_ACCOUNT_ID)
    ? localStorage.getItem(AZURE_ACCOUNT_ID)
    : null;
};

export const actions = {
  saveAccessToken,
  saveSession,
  clearSession,
  isLoggedIn,
  isTokenExpired,
  getExpiresAt,
  getAccessToken,
  setAzureAccountId,
  getAzureAccountId,
};
